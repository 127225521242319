<script setup>
import { ref, computed } from 'vue';

import { roundDecimals } from '../Helpers.js';
import { Battery0Icon, Battery50Icon, Battery100Icon } from "@heroicons/vue/24/outline";

const mode = ref('Lithium');
const showLabel = ref(false);

const minValue = computed(() => {
	if (mode.value=='Lithium') {
		if (props.voltage<5) return 3.2;
		if (props.voltage<16) return 12.8;
		if (props.voltage<32) return 25.6;
		return 51.2;
	}

	// lead acid 40%
	if (props.voltage<5) return 3;
	if (props.voltage<16) return 12;
	if (props.voltage<32) return 24;
	return 48;

});
const maxValue = computed(() => {
	if (mode.value=='Lithium') {
		if (props.voltage<5) return 3.32;
		if (props.voltage<16) return 13.3;
		if (props.voltage<32) return 26.6;
		return 53.1;
	}
	// lead acid 70%%
	if (props.voltage<5) return 3.125;
	if (props.voltage<16) return 12.5;
	if (props.voltage<32) return 25;
	return 53.1;
});

let props = defineProps({
	voltage: Number,
	percentage: Number
});

function switchMode() {
	if (mode.value=='Lead Acid') mode.value='Lithium';
	else mode.value='Lead Acid';
	showLabel.value=true;
	setTimeout(() => showLabel.value=false, 1000);
}
</script>

<template>
	<button v-if="voltage && !showLabel" class="flex gap-0 items-center cursor-pointer" @click="switchMode()" :title="mode">
		<Battery0Icon class="size-5 text-red-600 -rotate-90" v-if="voltage<=minValue" />
		<Battery50Icon class="size-5 text-yellow-600 -rotate-90" v-if="voltage>minValue && voltage<=maxValue" />
		<Battery100Icon class="size-5 text-green-600 -rotate-90" v-if="voltage>maxValue" />
		{{roundDecimals(voltage,2)}}V
	</button>
	<button v-if="percentage && !showLabel" class="flex gap-0 items-center cursor-pointer" @click="switchMode()" :title="mode">
		<Battery0Icon class="size-5 text-red-600 -rotate-90" v-if="percentage<=20" />
		<Battery50Icon class="size-5 text-yellow-600 -rotate-90" v-if="percentage>20 && percentage<=70" />
		<Battery100Icon class="size-5 text-green-600 -rotate-90" v-if="percentage>70" />
		{{roundDecimals(percentage,0)}}%
	</button>
	<div v-if="showLabel" class="text-xs">
		<div v-show="mode=='Lithium'">Lithium</div>
		<div v-show="mode=='Lead Acid'">Lead Acid</div>
	</div>
</template>