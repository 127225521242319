<template>
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" stroke="currentColor" stroke-width="1.5" fill="none" viewBox="0 0 25 25" class="w-4 h-4">
	<path class="cls-2" d="M17,12.5c0-1.73-.99-3.22-2.42-3.97l3.42-6.53-10-.12,2.74,6.48c-1.61,.68-2.74,2.28-2.74,4.14,0,1.71,.97,3.18,2.37,3.94l-3.37,6.44,10,.12-2.7-6.38c1.59-.69,2.7-2.28,2.7-4.12Z"/>
	<g>
		<path class="cls-1" d="M8.51,20c-.15-.08-.29-.16-.43-.25"/>
		<path class="cls-3" d="M6.41,18.43c-1.49-1.53-2.41-3.62-2.41-5.93,0-2.96,1.51-5.56,3.8-7.09"/>
		<path class="cls-1" d="M8.73,4.88c.15-.07,.3-.14,.45-.21"/>
	</g>
	<g>
		<path class="cls-1" d="M16.44,4.97c.15,.08,.29,.16,.43,.24"/>
		<path class="cls-4" d="M18.55,6.53c1.52,1.54,2.45,3.64,2.45,5.97,0,2.94-1.49,5.53-3.76,7.06"/>
		<path class="cls-1" d="M16.31,20.1c-.15,.07-.3,.14-.45,.21"/>
	</g>
</svg>
</template>
