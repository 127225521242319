<script setup>
import { ref, computed } from 'vue';
import { appStateStore } from "@/stores/AppStateStore.js";
import { settingsStore } from "@/stores/SettingsStore.js";
import { mapStyles } from "@/stores/ProMapStyles.js";

import { messagesStore } from "@/stores/MessagesStore.js";
const messages = messagesStore();

import { pointsStore } from "@/stores/PointsStore.js";
const points  = pointsStore();

import { useI18n } from 'vue-i18n'
const { t } = useI18n()

import ToggleSwitch from '@/PureComponents/ToggleSwitch.vue';

import { CogIcon, XMarkIcon, ArrowRightIcon, ArrowLeftIcon, LockClosedIcon, CheckIcon, ArrowUpOnSquareIcon, DocumentDuplicateIcon, ArrowRightStartOnRectangleIcon } from "@heroicons/vue/24/outline";

import { copyToClipboard, pointShortName } from '../Helpers.js';


const app = appStateStore();
const settings = settingsStore();


function copyClipboard(link) {
	copyToClipboard(link); 
	messages.success('Link copied to clipboard');
}


function copy(target) {
	copyToClipboard(calcUrl()); 
	messages.success('Link copied to clipboard');
	target.copied = true;
	setTimeout(function (target) {
		target.copied = false;
	}, 2000, target)
}

function canShare() {
	return navigator.share;
}
function clickShare() {
	if (canShare()) {
		navigator.share({
			title: 'puretrack.io',
			text: 'Shared from PureTrack.io',
			url: calcUrl(),
		})
		.then(() => console.log('Successful share'))
		.catch((error) => console.log('Error sharing', error));
	}
}

function calcUrl() {
	var string = "";
	var params = []; // array to put params into

	string = window.location.protocol + "//" + window.location.host + '/';

	if (app.selected!==null) {
		if (points.selected.rego) {
			params.push('a=' + points.selected.rego);
		} else {
			params.push('k=' + app.selected);
		}
	}

	for (var i=0; i<params.length; i++) {
		if (i==0) string += "?" + params[i];
		else string += "&" + params[i];
	}

	return string;
}

function googleLink() {
	return 'https://www.google.com/maps/place/' + points.selected.lat + '+' + points.selected.long + '/';
}

function appleLink() {
	return 'http://maps.apple.com/?q=' + points.selected.lat + ',' + points.selected.long;
}

</script>

<template>

	<div class="flex w-full sm:max-w-[600px] mx-auto overflow-hidden">
		<div class=" flex p-2 sm:px-8 sm:py-4 flex-col w-full overflow-hidden">
			<div class="frosted-panel flex flex-col overflow-hidden">

				<div class="flex items-center px-4 pt-4 pb-2 gap-4">

					<button title="close" class="round-button" @click="app.show=null">
						<XMarkIcon class="w-6 h-6" />
					</button>

					<h2 class="menu-title flex-grow ">{{ $t('share-menu.share') }} {{pointShortName(points.selected, 20, settings.shortenAircraftRegistrations)}}</h2>

					<button title="close" class="round-button opacity-0" @click="app.show=null">
						<XMarkIcon class="w-6 h-6" />
					</button>

				</div>


				<div class="flex flex-col overflow-y-scroll px-4 pb-4 pt-6">
					<div class="flex flex-col gap-6 w-full">


						<div class="menu-section">

							<button  class="menu-row menu-row-clickable w-full" v-if="canShare()" @click="clickShare()">

								<ArrowUpOnSquareIcon class="w-5 h-5 opacity-60"></ArrowUpOnSquareIcon> 

								{{ $t('share-menu.share') }}
								
							</button>


							<button class="menu-row menu-row-clickable w-full" 
								v-on:click="copy(points.selected)">

								<DocumentDuplicateIcon v-show="!points.selected?.copied" class="cursor-pointer h-5 w-5 opacity-60" image="copy"  alt="Copy to clipboard"></DocumentDuplicateIcon>
									<CheckIcon v-show="points.selected?.copied"  class="text-green-600 h-5 w-5"></CheckIcon>

								<div class="text-left">
									<div>{{ $t('share-menu.copy-link') }}</div>
									<div class="text-sm text-gray-600 dark:text-gray-400">{{calcUrl()}}</div>
								</div>

							</button>

						</div>



						<div class="menu-section">

							<div  class="menu-row menu-row-clickable w-full">

								<div class="flex-grow">{{ $t('share-menu.google-maps') }}</div>
								
								<button title="close" class="round-button" @click="copyClipboard(googleLink())">
									<DocumentDuplicateIcon class="w-5 h-5" />
								</button>

								<a :href="googleLink()" :title="$t('share-menu.go-to-google-maps')" class="round-button">
									<ArrowRightStartOnRectangleIcon class="w-5 h-5" />
								</a>

							</div>


							<div class="menu-row menu-row-clickable w-full" 
								>

								<div class="flex-grow">{{ $t('share-menu.apple-maps') }}</div>
								
								<button title="close" class="round-button" @click="copyClipboard(appleLink())">
									<DocumentDuplicateIcon class="w-5 h-5" />
								</button>

								<a :href="appleLink()" :title="$t('share-menu.go-to-apple-maps')" class="round-button">
									<ArrowRightStartOnRectangleIcon class="w-5 h-5" />
								</a>

							</div>

						</div>


					</div>

				</div>

			</div>


		</div>
	</div>

</template>