<script setup>
import { ref, watch, computed } from 'vue';
import { appStateStore } from "@/stores/AppStateStore.js";
import { settingsStore } from "../stores/SettingsStore.js";
import dayjs from 'dayjs';

import { distanceToMeters, computeSpeed, kilometersPerHourToMetersPerSecond, windImageNumber, renderTimeAgo } from '../Helpers.js';
import { XMarkIcon, ChevronLeftIcon } from "@heroicons/vue/24/outline";


const app = appStateStore();
const settings = settingsStore();

function getWind(wind) {
	//wind = kilometersPerHourToMetersPerSecond(wind);
	return windImageNumber(wind);
}
function calcWind(wind) {
	//wind = kilometersPerHourToMetersPerSecond(wind);
	return computeSpeed(wind, settings.speedUnits, 0);
}

function calcTime(ts) {
	return dayjs(ts).format('hh:mm');
}

function hasGusts(weathers) {
	if (!weathers) return false;
	for (let i=0; i<weathers.length; i++) {
		if (weathers[i].gust) return true;
	}
	return false;
}
function hasTemp(weathers) {
	if (!weathers) return false;
	for (let i=0; i<weathers.length; i++) {
		if (weathers[i].temperature) return true;
	}
	return false;
}

function closeStation() {
	app.selectedStation=null;
	app.selectedStationClicked=null;
}

</script>

<style>
	.infobox-label {
		@apply text-sm text-gray-400;
	}
</style>


<template>
<div class="w-full sm:px-2 sm:pb-2">



	<div class="h-full bg-gray-100 dark:bg-gray-800 text-black dark:text-white pointer-events-auto shadow-xl
		 sm:rounded-xl flex flex-col overflow-hidden select-none w-full  min-h-[190px]" 
		>


		<div class="header-row flex flex-wrap gap-2 w-full items-center border-b py-2 pl-2 pr-2 sm:pr-0 border-b-gray-300 dark:border-b-black ">


			<button class="round-button" 
				@click="app.showLegend='legend'; settings.followSelected=false;">
				<XMarkIcon class="size-4 sm:size-4" />
			</button>

			<div v-if="app.selectedStation" class="font-bold mx-2">
				{{app.selectedStation?.name}}
			</div>

			<LoadingHorizontal v-if="app.selectedStation==null" />


			<div class="flex flex-wrap gap-2 items-center " v-if="app.selectedStation">
				<a :href="'https://www.zephyrapp.nz/stations/' + app.selectedStation?.source_id" 
					v-if="app.selectedStation?.source=='zephyr'" 
					v-show="app.selectedStation?.source" 
					class="infobox nowrap infobox-clickable">
					<span class="infobox-label">{{$t('wind.source')}}</span> {{app.selectedStation?.source}}
				</a>

				<div v-if="app.selectedStation?.source!='zephyr'" 
					v-show="app.selectedStation?.source" 
					class="infobox nowrap ">
					<label class="infobox-label">{{$t('wind.source')}}</label>
					{{app.selectedStation?.source}}
				</div>

				<div v-if="!app.selectedStation?.link" 
					v-show="app.selectedStation?.type" 
					class="infobox nowrap">
					<label class="infobox-label">{{$t('wind.type')}}</label>
					{{app.selectedStation?.type}}
				</div>

				<a v-if="app.selectedStation?.link" 
					:href="app.selectedStation.link" 
					v-show="app.selectedStation?.type" 
					class="infobox nowrap infobox-clickable">
					<label class="infobox-label ">{{$t('wind.type')}}</label>
					{{app.selectedStation?.type}}
				</a>

				<div v-if="app.selectedStation?.last_ts" 
					class="infobox nowrap">
					{{renderTimeAgo(app.selectedStation?.last_ts.toDate(), app.currentTime)}} {{$t('wind.ago')}}
				</div>
			</div>

		</div>



		<div v-if="app.selectedStation?.weather" class="py-1 px-2 nowrap flex overflow-hidden w-full border-t-white dark:border-gray-700 border-t-1">


			<div class="flex flex-col">
				<label class="caption mt-0" v-show="hasTemp(app.selectedStation?.weather)">{{$t('wind.temp')}}</label>
				<label class="caption mt-4 mb-2" v-show="hasGusts(app.selectedStation?.weather)">{{$t('wind.gust')}}</label>
				<label class="caption mt-4">{{$t('wind.wind')}}</label>
			</div>


			<div v-if="1" class="flex overflow-x-scroll overflow-y-hidden px-4">
				<div v-for="weather in app.selectedStation?.weather" class="flex flex-col items-center flex-shrink-0 -ml-4">

					<div class="relative" style="margin-bottom: -5px;">
						<span v-show="weather.temperature!==null">{{weather.temperature}}&deg;</span>
						<span v-show="weather.temperature===null && hasTemp(app.selectedStation?.weather)===true ">&nbsp;</span>
					</div>

					<img v-show="weather.gust!==null" :src="'/images/icons/wind' + getWind(weather.gust) + '.png'" class="w-16 h-16 " width="16" height="16" :style="'transform: rotate('+weather.bearing+'deg);'">
					<div v-show="weather.gust!==null" class="relative text-white font-bold" style="margin-top: -45px;">{{calcWind(weather.gust)}}</div>

					<div v-if="hasGusts(app.selectedStation?.weather)===true && weather.gust===null">
						<img v-show="weather.gust!==null" :src="'/images/icons/wind1.png'" class="w-16 h-16 " width="16" height="16">

						<div v-show="weather.gust!==null" class="relative text-white font-bold" style="margin-top: -45px;">&nbsp;</div>
					</div>

					<img :src="'/images/icons/wind' + getWind(weather.wind) + '.png'" class="w-16 h-16"  width="16" height="16" :style="'transform: rotate('+weather.bearing+'deg);'">
					<div class="relative text-white font-bold" style="margin-top: -45px;">{{calcWind(weather.wind)}}</div>

					<div class="mt-2 text-sm">{{calcTime(weather.ts)}}</div>
				</div>
			</div>
		</div>


	</div>


</div></template>

