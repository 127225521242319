<script setup>
import { ref, computed } from 'vue';
import boundingBoxes from '/data/bounding-boxes.json';
import { appStateStore } from "@/stores/AppStateStore.js";
import { settingsStore } from "@/stores/SettingsStore.js";
import { mapStyles } from "@/stores/ProMapStyles.js";

import ToggleSwitch from '@/PureComponents/ToggleSwitch.vue';

import { CogIcon, XMarkIcon, ArrowRightIcon, ArrowLeftIcon, LockClosedIcon, CheckIcon, MoonIcon, SunIcon } from "@heroicons/vue/24/outline";

const app = appStateStore();
const settings = settingsStore();

import LanguageMenuRow from "@/MapComponentsLayers/LanguageMenuRow.vue";

var speedUnitsMenu = [
	{id: "knots", label: 'Knots', abbreviation: 'kts'},
	{id: "metersPerSecond", label: 'Meters per Second', abbreviation: 'm/s'},
	{id: "milesPerHour", label: 'Miles per Hour', abbreviation: 'mph'},
	{id: "kilometersPerHour", label: 'Kilometers per Hour', abbreviation: 'km/h'},
	{id: "feetPerMinute", label: 'Feet per Minute', abbreviation: 'ft/m'},
];

var distanceUnitsMenu = [
	{id: "kilometers", label: 'Kilometers', abbreviation: 'km'},
	{id: "miles", label: 'Miles', abbreviation: 'mi'},
	{id: "nauticalMiles", label: 'Nautical Miles', abbreviation: 'nm'},
];

var altitudeUnitsMenu = [
	{id: "feet", label: 'Feet', abbreviation: 'ft'},
	{id: "meters", label: 'Meters', abbreviation: 'm'},
];

var timeUnitsMenu = [
	{id: "12", label: '12hr with am/pm', abbreviation: '12hr'},
	{id: "24", label: '24hr', abbreviation: '24hr'},
];
var timezoneUnitsMenu = [
	{id: "user", label: 'Yours (' + Intl.DateTimeFormat().resolvedOptions().timeZone + ')', abbreviation: 'Yours'},
	{id: "local", label: 'Local time', abbreviation: 'Local'},
	{id: "utc", label: 'UTC', abbreviation: 'UTC'},
];

function resetSettings() {
	settings.$reset();
	location.reload();
}
</script>

<template>

	<div class="flex w-full sm:max-w-[600px] mx-auto overflow-hidden">
		<div class=" flex p-2 sm:px-8 sm:py-4 flex-col w-full overflow-hidden">
			<div class="frosted-panel flex flex-col overflow-hidden">

				<div class="flex items-center px-4 pt-4 pb-2 gap-4">

					<button title="close" class="round-button" @click="app.show=app.showBack.pop()">
						<ArrowLeftIcon class="w-6 h-6" />
					</button>

					<h2 class="menu-title flex-grow ">{{ $t('settings-menu.settings') }}</h2>

					<button title="close" class="round-button" @click="app.show=null">
						<XMarkIcon class="w-6 h-6" />
					</button>

				</div>

				<div class="flex flex-col overflow-y-scroll px-4 pb-4 pt-6 gap-4">


					<div class="menu-section">
						<LanguageMenuRow></LanguageMenuRow>
					</div>

					<div  class="menu-section">

						<div class="menu-row flex-wrap justify-between">
							<h3>{{ $t('settings-menu.altitude') }}</h3>

							<div class="flex flex-wrap gap-2 text-sm">

								<button v-for="item in altitudeUnitsMenu"
									:key="item.id"
									class="border border-lime-600 dark:border-lime-500 py-1 px-2 rounded-lg cursor-pointer"
									@click="settings.altitudeUnits = item.id"
									:class="settings.altitudeUnits==item.id ? 'bg-lime-600 dark:bg-lime-500 text-white dark:text-black' : 'hover:bg-gray-200 dark:hover:bg-gray-900'">
										{{ item.abbreviation }}
								</button>
							</div>
						</div>

						<div class="menu-row flex-wrap justify-between">
							<h3>{{ $t('settings-menu.speed') }}</h3>

							<div class="flex flex-wrap gap-2 text-sm">

								<button v-for="item in speedUnitsMenu"
									:key="item.id"
									class="border border-lime-600 dark:border-lime-500 py-1 px-2 rounded-lg cursor-pointer"
									@click="settings.speedUnits = item.id"
									:class="settings.speedUnits==item.id ? 'bg-lime-600 dark:bg-lime-500 text-white dark:text-black' : 'hover:bg-gray-200 dark:hover:bg-gray-900'">
										{{ item.abbreviation }}
								</button>
							</div>
						</div>

						<div class="menu-row flex-wrap justify-between">
							<h3>{{ $t('settings-menu.vertical-speed') }}</h3>

							<div class="flex flex-wrap gap-2 text-sm">

								<button v-for="item in speedUnitsMenu"
									:key="item.id"
									class="border border-lime-600 dark:border-lime-500 py-1 px-2 rounded-lg cursor-pointer"
									@click="settings.verticalUnits = item.id"
									:class="settings.verticalUnits==item.id ? 'bg-lime-600 dark:bg-lime-500 text-white dark:text-black' : 'hover:bg-gray-200 dark:hover:bg-gray-900'">
										{{ item.abbreviation }}
								</button>
							</div>
						</div>

						<div class="menu-row flex-wrap justify-between">
							<h3>{{ $t('settings-menu.distance') }}</h3>

							<div class="flex flex-wrap gap-2 text-sm">

								<button v-for="item in distanceUnitsMenu"
									:key="item.id"
									class="border border-lime-600 dark:border-lime-500 py-1 px-2 rounded-lg cursor-pointer"
									@click="settings.distanceUnits = item.id"
									:class="settings.distanceUnits==item.id ? 'bg-lime-600 dark:bg-lime-500 text-white dark:text-black' : 'hover:bg-gray-200 dark:hover:bg-gray-900'">
										{{ item.abbreviation }}
								</button>
							</div>
						</div>

						<div class="menu-row flex-wrap justify-between">
							<h3>{{ $t('settings-menu.time-format') }}</h3>

							<div class="flex flex-wrap gap-2 text-sm">

								<button v-for="item in timeUnitsMenu"
									:key="item.id"
									class="border border-lime-600 dark:border-lime-500 py-1 px-2 rounded-lg cursor-pointer"
									@click="settings.timeUnits = item.id"
									:class="settings.timeUnits==item.id ? 'bg-lime-600 dark:bg-lime-500 text-white dark:text-black' : 'hover:bg-gray-200 dark:hover:bg-gray-900'">
										{{ item.label }}
								</button>
							</div>
						</div>

						<div class="menu-row flex-wrap justify-between">
							<h3>{{ $t('settings-menu.timezone') }}</h3>

							<div class="flex flex-wrap gap-2 text-sm">

								<button v-for="item in timezoneUnitsMenu"
									:key="item.id"
									class="border border-lime-600 dark:border-lime-500 py-1 px-2 rounded-lg cursor-pointer"
									@click="settings.timezoneUnits = item.id"
									:class="settings.timezoneUnits==item.id ? 'bg-lime-600 dark:bg-lime-500 text-white dark:text-black' : 'hover:bg-gray-200 dark:hover:bg-gray-900'">
										{{ item.label }}
								</button>
							</div>
						</div>

					</div>


					<div  class="menu-section">

						<div class="menu-row flex-wrap justify-between">
							<h3>{{ $t('settings-menu.user-interface') }}</h3>

							<div class="flex flex-wrap gap-2 text-sm">

								<button
									class="border flex justify-center gap-2 border-lime-600 dark:border-lime-500 py-1 px-2 rounded-lg cursor-pointer"
									@click="settings.darkMode = 'dark'"
									:class="settings.darkMode=='dark' ? 'bg-lime-600 dark:bg-lime-500 text-white dark:text-black' : 'hover:bg-gray-200 dark:hover:bg-gray-900'">
										<MoonIcon class="size-5" />
										{{ $t('settings-menu.dark') }}
								</button>

								<button
									class="border flex justify-center gap-2 border-lime-600 dark:border-lime-500 py-1 px-2 rounded-lg cursor-pointer"
									@click="settings.darkMode = 'light'"
									:class="settings.darkMode=='light' ? 'bg-lime-600 dark:bg-lime-500 text-white dark:text-black' : 'hover:bg-gray-200 dark:hover:bg-gray-900'">
										<SunIcon class="size-5" />
										{{ $t('settings-menu.light') }}
								</button>
							</div>
						</div>


					</div>

					<div  class="menu-section">

						<div class="menu-row menu-row-clickable" @click="settings.shortenAircraftRegistrations = !settings.shortenAircraftRegistrations">
							<div class="whitespace-nowrap flex items-center gap-2">
								<ToggleSwitch  title="Use Short Aircraft Registrations" id="shortenAircraftRegistrations" v-model="settings.shortenAircraftRegistrations"></ToggleSwitch>

								<div class="flex flex-col">
									<label for="shortenAircraftRegistrations" class="flex-grow">{{ $t('settings-menu.shorten-registrations') }}</label>
									<div class="caption">{{ $t('settings-menu.removes-country-prefix') }}</div>
								</div>

							</div> 
						</div>

						
						<div class="menu-row menu-row-clickable" @click="settings.simpleMode = !settings.simpleMode" :title="$t('settings-menu.simple-icons')">

							<div class="whitespace-nowrap flex items-center gap-2">
								<ToggleSwitch  id="simpleMode" v-model="settings.simpleMode"></ToggleSwitch>
								<div class="flex flex-col">
									<label for="simpleMode" class="flex-grow">{{ $t('settings-menu.simple-icons') }}</label>
									<div class="caption">{{ $t('settings-menu.dave-mode') }}</div>
								</div>
							</div> 
						</div>

						<div class="menu-row menu-row-clickable" @click="app.showBack.push('settings-menu'); app.show='labels-menu';" :title="$t('settings-menu.label-options')">
							<div class="flex-grow items-start">{{ $t('settings-menu.label-options') }}</div>
							<button class="round-button" >
								<ArrowRightIcon class="w-4 h-4" />
							</button>
						</div>

						<div class="menu-row menu-row-clickable" @click="settings.showDebug = !settings.showDebug">
							<div class="whitespace-nowrap flex items-center gap-2">
								<ToggleSwitch  :title="$t('settings-menu.show-debug')" id="debug" v-model="settings.showDebug"></ToggleSwitch>
								<label for="debug" class="flex-grow">{{ $t('settings-menu.show-debug') }}</label>
							</div> 
						</div>

					</div>


					<div  class="menu-section">

						<div class="menu-row menu-row-clickable">
								<div class="flex items-center gap-2" @click="settings.loadOtherMapIconsWhileBrowsing = !settings.loadOtherMapIconsWhileBrowsing">

									<ToggleSwitch  :title="$t('settings-menu.load-map-icons-history')" id="loadOtherMapIconsWhileBrowsing" v-model="settings.loadOtherMapIconsWhileBrowsing"></ToggleSwitch>

									<div class="flex flex-col gap-1">
										<label for="loadOtherMapIconsWhileBrowsing" class="flex-grow">{{ $t('settings-menu.load-map-icons-history') }}</label>
										<div class="text-gray-600 dark:text-gray-300 text-sm  text-wrap grow">{{ $t('settings-menu.load-map-icons-history-description') }}</div>

									</div>
								</div>

						</div>

					</div>

				<button class="menu-button menu-row-clickable w-1/2 mx-auto" @click="resetSettings()">
					{{ $t('settings-menu.reset') }}
				</button>
			</div>


		</div>
	</div>
</div>
</template>