<script setup>
import { ref, computed } from 'vue';
import boundingBoxes from '/data/bounding-boxes.json';
import { appStateStore } from "@/stores/AppStateStore.js";
import { settingsStore } from "@/stores/SettingsStore.js";
import { mapStyles } from "@/stores/ProMapStyles.js";

import { messagesStore } from "@/stores/MessagesStore.js";
const messages = messagesStore();

import { useGroups } from "@/Composables/UseGroups.js";
const groups = useGroups();

import ToggleSwitch from '@/PureComponents/ToggleSwitch.vue';

import { CogIcon, XMarkIcon, ArrowRightIcon, ArrowLeftIcon, LockClosedIcon, LockOpenIcon, CheckIcon, ChevronDownIcon, ChevronRightIcon, DocumentDuplicateIcon, FunnelIcon, ViewfinderCircleIcon } from "@heroicons/vue/24/outline";

import { useDrawCompTasks } from "@/Composables/UseDrawCompTasks.js";
const drawTasks = useDrawCompTasks();

const app = appStateStore();
const settings = settingsStore();


function addToGroup() {
	if (!groups.temporaryPoint.value) return;

	groups.addToGroup(groups.temporaryPoint.value)
}

</script>

<template>

	<div class="flex w-full sm:max-w-[600px] mx-auto overflow-hidden">
		<div class=" flex p-2 sm:px-8 sm:py-4 flex-col w-full overflow-hidden">
			<div class="frosted-panel flex flex-col overflow-hidden">

				<div class="flex items-center px-4 pt-4 pb-2 gap-4">

					<button title="close" class="round-button" @click="app.show=null">
						<XMarkIcon class="w-6 h-6" />
					</button>

					<h2 v-if="groups.group.value" class="menu-title flex-grow ">{{ groups.group.value.name }}</h2>

					<button title="close" class="round-button opacity-0" @click="app.show=null">
						<XMarkIcon class="w-6 h-6" />
					</button>

				</div>


				<div class="flex flex-col overflow-y-scroll px-4 pb-4 pt-1 gap-4">
					<h3 class="menu-section-title">{{$t('group-menu.password-required')}}</h3>


					<div class="menu-section" v-if="groups.group.value?.protected">

						<div class="menu-row flex items-center gap-2">

							<LockClosedIcon class="menu-row-icon" />

							<div class="flex flex-col gap-2 w-full">
								<input type="password" v-model="groups.password.value" class="input w-full">

								<div class="flex gap-4 items-center">
									<button @click="addToGroup()" class="menu-button !px-4 flex gap-2 items-center">
										Add to Group 
										<spinner v-if="groups.temporaryPoint.value.groupLoading>0" class="size-4" />
									</button>


									<button @click="app.show=null" class="menu-button  !px-4">Cancel</button>
								</div>
							</div>

						</div>

					</div>

					<div class="text-center my-4">{{$t('group-menu.password-remembered')}}</div>

				</div>



			</div>


		</div>
	</div>

</template>