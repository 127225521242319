<script setup>
import { ref, watch, computed } from 'vue';
import { settingsStore } from "@/stores/SettingsStore.js";
import { appStateStore } from "@/stores/AppStateStore.js";
import { skySightStore } from "@/stores/SkySightStore.js";
import { pointShortName, renderHeight, computeHeight, getIconImage, computeSpeed, renderTimeAgo, roundDecimals } from '../Helpers.js';

const settings = settingsStore();
const app = appStateStore();
const skysight = skySightStore();

const unitsLabel = ref(null);

const legend = computed(() => {

	if (!skysight.currentLayer) return [];

	let rows = [];
	let value = skysight.currentLayer.min;

	let roundPlaces = 0;
	if (skysight.currentLayer.step<1) roundPlaces=1;

	let scaledColours = skysight.scaledColours();
	unitsLabel.value = skysight.currentLayer.unit;

	for (var i=0; i<scaledColours.length; i++) {

		let valueConverted = value;
		switch (skysight.currentLayer.unit) {
			case 'm':
				valueConverted = roundDecimals(roundDecimals(computeHeight(value, settings.altitudeUnits),0)/100,0)*100;
				unitsLabel.value = getAltitudeUnit(settings.altitudeUnits);
				break;
			case 'm/s':
				valueConverted = computeSpeed(value, settings.verticalUnits, 1);
				unitsLabel.value = getSpeedUnit(settings.verticalUnits);
				break;
			case 'c':
				unitsLabel.value = '°C';
				break;
		}

		let textColour = 'black';
		switch (scaledColours[i]) {
			case 'rgb(94, 0, 131)':
			case 'rgb(150, 0, 85)':
			case 'rgb(206, 0, 40)':
			case 'rgb(255, 8, 0)':
			case 'rgb(255, 62, 0)':
				
			case 'rgb(0, 0, 143)':
			case 'rgb(0, 0, 228)':
			case 'rgb(0, 58, 255)':
			case 'rgb(0, 144, 255)':
				textColour = 'white';
				break;
		}

		let item = {
			id: i,
			colour: scaledColours[i],
			value: valueConverted,
			textColour: textColour,
		}
		rows.push(item);

		value = roundDecimals(value + skysight.currentLayer.step, roundPlaces);
	}
	return rows.reverse();
})



function getAltitudeUnit(setting) {
	switch (setting) {
		case 'feet': return 'ft'; break;
		case 'meters': return 'm'; break;
	}
}

function getSpeedUnit(setting) {
	switch (setting) {
		case 'knots': return 'kts'; break;
		case 'metersPerSecond': return 'm/s'; break;
		case 'milesPerHour': return 'mph'; break;
		case 'kilometersPerHour': return 'km/h'; break;
		case 'feetPerMinute': return 'ft/m'; break;
	}
}

</script>


<style>
.v-enter-active,
.v-leave-active {
	transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
	opacity: 0;
}
</style>


<template>
	<Transition>
		<div class="flex flex-col w-fit rounded bg-white pointer-events-auto " v-if="skysight.currentLayer">
			
			<div class="first:rounded-t bg-gray-200 dark:bg-gray-800 text-black dark:text-white   text-xs px-1 py-0.5 max-w-[50px] whitespace-nowrap">
				<div class="overflow-clip">{{unitsLabel}}</div>
			</div>
			<template v-for="row in legend" :key="row.id">
					<div class="text-black px-1 py-0.5 text-xs font-bold  last:rounded-b"
						:class="row.textColour=='white' ? 'text-white' : 'text-black'"
						:style="'background-color: ' + row.colour" >
						{{row.value}}
					</div>
			</template>

		</div>
	</Transition>


</template>

