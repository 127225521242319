<script setup>
import { ref, computed } from 'vue';
import boundingBoxes from '/data/bounding-boxes.json';
import { appStateStore } from "@/stores/AppStateStore.js";
import { settingsStore } from "@/stores/SettingsStore.js";
import { mapStyles } from "@/stores/ProMapStyles.js";

import { messagesStore } from "@/stores/MessagesStore.js";
const messages = messagesStore();

import { useGroups } from "@/Composables/UseGroups.js";
const groups = useGroups();

import ToggleSwitch from '@/PureComponents/ToggleSwitch.vue';

import { CogIcon, XMarkIcon, ArrowRightIcon, ArrowLeftIcon, LockClosedIcon, LockOpenIcon, CheckIcon, ChevronDownIcon, ChevronRightIcon, DocumentDuplicateIcon, FunnelIcon, ViewfinderCircleIcon, InformationCircleIcon, ClockIcon, ShareIcon } from "@heroicons/vue/24/outline";

import { useDrawCompTasks } from "@/Composables/useDrawCompTasks.js";
const drawTasks = useDrawCompTasks();

import { copyToClipboard } from '../Helpers.js';

import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const app = appStateStore();
const settings = settingsStore();

var firstLoad = ref(true);

function clickFollow() {
	if (settings.followGroup==true) {
		settings.pauseFollowing=false;
		goToSelected();
	} else {
		settings.pauseFollowing=true;
	}
}


function copyUrl() {
	copyToClipboard(groups.group.value.url); 
	messages.success(t('app.copied-to-clipboard'));
}

</script>

<template>

	<div class="flex w-full sm:max-w-[600px] mx-auto overflow-hidden">
		<div class=" flex p-2 sm:px-8 sm:py-4 flex-col w-full overflow-hidden">
			<div class="frosted-panel flex flex-col overflow-hidden">

				<div class="flex items-center px-4 pt-6 pb-2 gap-4">

					<button title="close" class="round-button" @click="app.show=null">
						<XMarkIcon class="w-6 h-6" />
					</button>
					
					<h2 v-if="groups.group" class="menu-title flex-grow ">{{ groups.group.value.name }}</h2>

					<button title="close" class="round-button opacity-0" @click="app.show=null">
						<XMarkIcon class="w-6 h-6" />
					</button>

				</div>


				<div class="flex flex-col overflow-y-scroll px-4 pb-4 pt-6 gap-4">
					
					<div class="menu-section">

						<label for="filterGroup" class="menu-row menu-row-clickable ">

							<FunnelIcon class="menu-row-icon" />

							<div>
								<input class="mr-1" type="checkbox" id="filterGroup" :value="true" v-model="settings.filterGroup" @change="app.restartLoop=true;"> {{$t('group-menu.filter-to-group')}}
							</div>
						</label>

						<label for="followGroup" class="menu-row menu-row-clickable">

							<ViewfinderCircleIcon class="menu-row-icon" />

							<div>
								<input class="mr-1" type="checkbox" id="followGroup" :value="true" v-model="settings.followGroup"> {{$t('group-menu.follow-group')}}
							</div>
						</label>

						<label for="loadAllGroup" class="menu-row menu-row-clickable">

							<ClockIcon class="menu-row-icon flex-shrink-0" />

							<div>
								<input class="mr-1" type="checkbox" id="loadAllGroup" :value="true" v-model="settings.loadAllGroup"> {{$t('group-menu.load-all')}}
								<div class="text-gray-600 dark:text-gray-400">{{$t('group-menu.load-all-description')}}</div>
							</div>
						</label>

						<label  class="menu-row menu-row-clickable">

							<ShareIcon class="menu-row-icon" />

							<div class="flex items-center  gap-2 flex-grow"  @click="app.showBack.push('group-menu'); app.show='tasks-menu';">
								<button v-if="groups.group" class="flex-grow text-left">
									{{ $t('tasks.tasks') }}
								</button>

								<button :title="$t('puretrack-menu.change-mode')" class="round-button">
									<ArrowRightIcon class="w-3 h-3" />
								</button>
							</div>
						</label>

						<div @click="copyUrl(groups.group.value.url)" class="menu-row menu-row-clickable justify-between w-full cursor-pointer">

							<div class="flex flex-col gap-1 items-start whitespace-nowrap max-w-full overflow-hidden">
								<span>{{ $t('contests-menu.puretrack-link') }}</span>
								<span class="text-sm max-w-full text-gray-600 dark:text-gray-400 overflow-hidden  overflow-ellipsis">
									{{groups.group.value.url}}
								</span>
							</div>

							<button :title="$t('app.copy-to-clipboard')" class="round-button"  >
								<DocumentDuplicateIcon class="w-6 h-6" />
							</button>

						</div>

					</div>


					<a v-if="groups.group" :href="'/groups/'+ groups.group.value.id +'/manage'" class="menu-button">{{$t('members.manage-members')}} {{groups.group.value.members.length}}</a>



					<div class="menu-section" v-if="groups.group.value?.protected">

						<div class="menu-row flex items-center gap-2">

							<LockClosedIcon class="menu-row-icon" />

							<div class="flex flex-col gap-2 w-full">
								{{$t('group-menu.group-password')}}
								<div class="text-sm text-gray-600 dark:text-gray-400">{{$t('group-menu.group-password-description')}}</div>
								<input type="password" v-model="groups.password.value" class="input w-full">
							</div>

						</div>

					</div>

					<div class="menu-section" v-if="!groups.group.value?.protected">

						<div class="menu-row flex items-center gap-2">

							<LockOpenIcon class="menu-row-icon" />

							<div class="flex flex-col gap-2 w-full">
								{{$t('group-menu.group-password')}}
								<div class="text-sm text-gray-600 dark:text-gray-400">{{$t('group-menu.no-group-password')}}</div>
							</div>

						</div>

					</div>

					<div class="menu-section">

						<div class="menu-row flex gap-2 ">


							<div class="prose dark:text-white">
								<p><InformationCircleIcon class="size-5 inline" /> To add an item to this group:</p>

								<ol>
									<li>Enter the password above (if required).</li>
									<li>Turn off the "Filter to group" above or click the <FunnelIcon class="size-5 inline" /> next to the group name.</li>
									<li>Go back to the main map.</li>
									<li>Tap the map marker you want to add.</li>
									<li>Tap the "Add to Group" button.</li>
								</ol>

								<p>Note you'll need your tracker to appear on the map to add to the group.</p>
							</div>

						</div>

					</div>



				</div>



			</div>


		</div>
	</div>

</template>