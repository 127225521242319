<script setup>
import { ref, computed } from 'vue';

import { appStateStore } from "@/stores/AppStateStore.js";
import { settingsStore } from "@/stores/SettingsStore.js";
import { useGroups } from "@/Composables/UseGroups.js";
const groups = useGroups();

import { CogIcon, XMarkIcon, ArrowRightIcon, ArrowLeftIcon, LockClosedIcon, CheckIcon } from "@heroicons/vue/24/outline";

const app = appStateStore();
const settings = settingsStore();
</script>

<template>

	<div>Groups!</div>

</template>