<script setup>

import { ref, onMounted, onUnmounted, watch } from 'vue'
import { settingsStore } from "@/stores/SettingsStore.js";

const settings = settingsStore();


function onKeyUp(event) {
	// console.log('doing key event');

	if (document.activeElement.nodeName=='INPUT') return;
	if (document.activeElement.nodeName=='TEXTAREA') return;

	if (event.key == 'd' ) {
		settings.darkMode == 'light' ? settings.darkMode='dark' : settings.darkMode='light';
	}
}

onMounted(function() {
	// global map hotkeys
	document.addEventListener('keyup', onKeyUp);

});

onUnmounted(function() {
	document.removeEventListener('keyup', onKeyUp);
});

</script>

<template>
	
</template>