<script setup>
import { ref } from 'vue';
import { ArrowRightIcon} from "@heroicons/vue/24/outline";
import boundingBoxes from '/data/bounding-boxes.json';
import { appStateStore } from "@/stores/AppStateStore.js";
import { settingsStore } from "@/stores/SettingsStore.js";

const app = appStateStore();
const settings = settingsStore();
let country=ref(null);

import { useMapTools } from "@/Composables/MapTools.js";
const { workOutMapVisibleSpace } = useMapTools();

import LanguageMenuRow from "@/MapComponentsLayers/LanguageMenuRow.vue";


function getUsersCountry() {
	window.axios.get('https://geoip.puretrack.io').then(function (response) {
		country.value = response.data;
		goToCountry();
	}).catch(error => {
		console.log(error.errors);
	});
}

function goToCountry() {
	if (!country.value?.code) {
		// console.log('No country code found');
		return;
	}

	// don't go to country if we have been passed a selected item
	if (app.selected) {
		// console.log('not going to country due to selected item');
		return;
	}

	// console.log('going to ' + country.value.code);
	// console.log(boundingBoxes[country.value.code][1]);

	workOutMapVisibleSpace();
	app.map.fitBounds(boundingBoxes[country.value.code][1]);
}

function clickGliding() {
	app.setMode('gliding');
	settings.showWelcomeScreen = false;
	settings.legendAlt = true;
	settings.legendLabelSize = 8;
}
function clickMarine() {
	app.setMode('marine');
	settings.showWelcomeScreen = false;
	settings.legendAlt = false;
	settings.legendLabelSize = 16;
}
function clickGA() {
	app.setMode('ga');
	settings.showWelcomeScreen = false;
	settings.legendAlt = true;
	settings.legendLabelSize = 8;
}
function clickFreeflight() {
	app.setMode('freeflight');
	settings.showWelcomeScreen = false;
	settings.legendLabelSize = 16;
	settings.legendAlt = true;
}
function clickAll() {
	app.setMode('all');
	settings.showWelcomeScreen = false;
	settings.legendAlt = true;
	settings.legendLabelSize = 8;
}
setTimeout(getUsersCountry,1000);

</script>



<template>

	<div class="flex w-full sm:max-w-[600px] mx-auto overflow-hidden">
		<div class=" flex flex-col p-2 sm:px-8 sm:py-4 sm:px-8  w-full overflow-hidden">
			<div class="frosted-panel flex flex-col overflow-hidden">
			
				<div class="flex gap-4 w-fit mx-auto items-center my-4 mx-auto">
					<img src="/images/puretrack-logo.png" width="50" class="w-[30px] xs:w-[40px] sm:w-[50px]">
					<h1 class="text-2xl xs:text-3xl sm:text-4xl dark:text-gray-100"><span class="font-bold">Pure</span>Track<span class="font-bold">.io</span></h1>
				</div>

				<h2 class="text-center text-lg sm:text-xl mx-6">{{ $t('welcome.slogan') }}</h2>

				<div class="overflow-y-scroll px-4 pt-4 pb-6 gap-4">

					<LanguageMenuRow :changeUnits="true"></LanguageMenuRow>

					<p class="mb-4 text-center text-gray-600 dark:text-gray-300">{{ $t('welcome.hello') }} </p>

					<div class="menu-section">

						<div class="menu-row menu-row-clickable" @click="clickAll()">
							<img src="/images/icons/jet_w.svg" class="w-8 h-8">

							<h2 class="flex-grow text-lg">{{ $t('welcome.show-all') }}</h2>

							<button class="round-button">
								<ArrowRightIcon class="w-5 h-5" />
							</button>
						</div>


						<div class="menu-row menu-row-clickable" @click="clickGA()">
							<img src="/images/icons/plane_w.svg" class="w-8 h-8">

							<div class="flex flex-col flex-grow">
								<h2 class="text-lg">{{ $t('welcome.general-aviation') }}</h2>
								<div class="text-gray-600 dark:text-gray-300 text-sm">{{ $t('welcome.ga-description') }}</div>
							</div>

							<button class="round-button">
								<ArrowRightIcon class="w-5 h-5" />
							</button>
						</div>

						<div class="menu-row menu-row-clickable" @click="clickGliding()">
							<img src="/images/icons/glider_w.svg" class="w-8 h-8">

							<div class="flex flex-col flex-grow">
								<h2 class="text-lg">{{ $t('welcome.gliding') }}</h2>
								<div class="text-gray-600 dark:text-gray-300 text-sm">{{ $t('welcome.gliding-description') }}</div>
							</div>

							<button class="round-button">
								<ArrowRightIcon class="w-5 h-5" />
							</button>
						</div>

						<div class="menu-row menu-row-clickable" @click="clickFreeflight()">
							<img src="/images/icons/paraglider_w.svg" class="w-8 h-8">

							<div class="flex flex-col flex-grow">
								<h2 class="flex-grow text-lg">{{ $t('welcome.freeflight') }}</h2>
								<div class="text-gray-600 dark:text-gray-300 text-sm">{{ $t('welcome.freeflight-description') }}</div>
							</div>

							<button class="round-button">
								<ArrowRightIcon class="w-5 h-5" />
							</button>
						</div>

						<div class="menu-row menu-row-clickable" @click="clickMarine()">
							<img src="/images/icons/sailboat_w.svg" class="w-8 h-8">

							<div class="flex flex-col flex-grow">
								<h2 class="flex-grow text-lg">{{ $t('welcome.marine') }}</h2>
								<div class="text-gray-600 dark:text-gray-300 text-sm">{{ $t('welcome.marine-description') }}</div>
							</div>

							<button class="round-button">
								<ArrowRightIcon class="w-5 h-5" />
							</button>
						</div>


					</div>


						<p class="mt-6 text-center text-gray-800 dark:text-gray-200">Warning: Not to be used for navigation</p>

						<p class="my-2 text-center text-gray-800 dark:text-gray-200">By using this site you agree to the PureTrack <a href="/terms" class="link">terms and conditions</a></p>


				</div>

			</div>
		</div>
	</div>
</template>