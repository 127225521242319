<script setup>

import { ref, watch } from 'vue';

import { settingsStore } from "@/stores/SettingsStore.js";
const settings = settingsStore();
import { appStateStore } from "@/stores/AppStateStore.js";
const app = appStateStore();
const { goTo, goToSelected } = useMapTools();
import { messagesStore } from "@/stores/MessagesStore.js";
const messages = messagesStore();

import { useGroups } from "@/Composables/UseGroups.js";
const groups = useGroups();

import { useMapTools } from "@/Composables/MapTools.js";
const mapTools = useMapTools();

import { XMarkIcon, UserGroupIcon, ChevronDoubleLeftIcon,  ChevronDoubleRightIcon, FunnelIcon, ViewfinderCircleIcon, Bars3Icon } from '@heroicons/vue/24/outline';

const props = defineProps(['slug']);

// watch(() => settings.pauseFollowing, (currentValue, oldValue) => {
// 	if (currentValue==true && settings.followGroup==true) {
// 		settings.followGroup=false;
// 	}
// });

watch(() => props.slug, (currentValue, oldValue) => {
	console.log('Loading group');
	groups.loadGroup(); // load the new comp
});


function toggleFollow() {
	settings.followGroup=!settings.followGroup;
	if (settings.followGroup) {
		mapTools.goToSelected();
		settings.pauseFollowing=false; 
	}
}

groups.loadGroup();


settings.filterGroup=true;


</script>

<template>
	<button @click="app.show='group-menu'" :title="groups.group?.name"  class="ui-pill pl-1 pr-1 py-1 self-center flex items-center gap-2 w-full bg-green-700 text-white">

		<button @click.stop="groups.closeGroup()" :title="$t('group-menu.close-group')"
			class="pill-button flex-shrink-0">
			<XMarkIcon class="size-4 text-green-700" />
		</button>
		
		<UserGroupIcon class="w-5 h-5 flex-shrink-0 opacity-40" />
		<span v-if="groups.loading.value>0" class="flex items-center"><spinner class="size-5" /></span>
		<span v-if="groups.group" class="flex-grow whitespace-nowrap overflow-hidden overflow-ellipsis">{{groups.group?.value?.name}}</span>

		<button  :title="$t('group-menu.filter-to-group')" 
			class=" pill-button  flex-shrink-0"
			:class="[settings.filterGroup ? 'pill-button-selected' : 'round-button']" 
			@click.stop="settings.filterGroup=!settings.filterGroup; app.restartLoop=true;">
			<FunnelIcon class="size-4 text-green-700"  />
		</button>

		<button :title="$t('group-menu.follow-group')"
			class="pill-button flex-shrink-0"
			:class="[settings.followGroup ? 'pill-button-selected' : 'round-button']" 
			@click.stop="toggleFollow()">
			<ViewfinderCircleIcon class="size-4 text-green-700" />
		</button>


		<button :title="$t('app.menu')"
			class="pill-button flex-shrink-0"
			@click="app.show='group-menu'">
			<Bars3Icon class="size-4 text-green-700" />
		</button>


		<!-- <button class="round-button !bg-opacity-50" v-show="open"  @click.stop="open=!open">
			<ChevronDoubleLeftIcon class="size-3" />
		</button>
		<button class="round-button !bg-opacity-50" v-show="!open" @click.stop="open=!open">
			<ChevronDoubleRightIcon class="size-3" />
		</button> -->

	</button>

</template>
