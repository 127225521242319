<script setup>
import { ref, computed, onMounted } from 'vue';
import { appStateStore } from "@/stores/AppStateStore.js";
import { settingsStore } from "@/stores/SettingsStore.js";
import { messagesStore } from "@/stores/MessagesStore.js";
import { tasksStore } from "@/stores/TasksStore.js";

import { useGroups } from "@/Composables/UseGroups.js";
const groups = useGroups();

import TaskScanner from "@/MapComponentsLayers/TaskScanner.vue";
import ToggleSwitch from '@/PureComponents/ToggleSwitch.vue';

import { CogIcon, XMarkIcon, ArrowRightIcon, ArrowLeftIcon, LockClosedIcon, CheckIcon, ChevronDownIcon, ChevronRightIcon, TrophyIcon, TrashIcon, ShareIcon, UserGroupIcon } from "@heroicons/vue/24/outline";

import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const app = appStateStore();
const settings = settingsStore();
const messages = messagesStore();
const tasks = tasksStore();

import { apiDateToJS, renderTimeAgo, formatTimeFromJSDate } from '../Helpers.js';

const uploading = ref(false);

const tab=ref('list');


function foundQR(code) 
{
	// console.log('Found code');
	// console.log(code);
	tab.value = 'list'; // back to list

	let formData = {
		'data': code.decodedText
	}

	axios.post('/api/upload-xctask', formData).then(function (response) {
		messages.success( 'Uploaded XCTask data' );
		tab.value='list';
		console.log(response.data);
		tasks.addToActiveTasksArray(response.data);
		tasks.loadTasks();
	}).catch(error => {
		console.log(error);
		if (error.response?.data?.message) {
			messages.error(t('tasks.failed-to-upload') + ': ' + error.response.data.message);
		} else {
			messages.error(t('tasks.failed-to-upload'));
		}
	});
}



function uploadTask() {
	uploading.value = true;
	var formData = new FormData();
	var uploadFile = document.querySelector('#cupfile');
	formData.append("cupfile", uploadFile.files[0]);

	axios.post('/api/upload-task', formData, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	}).then(function (response) {
		// console.log('response:');
		// console.log(response);
		messages.success( t('tasks.file-uploaded') );
		uploading.value = false;
		tab.value='list';
		console.log(response.data);
		tasks.addToActiveTasksArray(response.data);
		tasks.loadTasks();
	}).catch(error => {
		console.log(error);
		if (error.response?.data?.message) {
			messages.error(t('tasks.failed-to-upload') + ': ' + error.response.data.message);
		} else {
			messages.error(t('tasks.failed-to-upload'));
		}
		
		uploading.value = false;
	});

}


function inTasksList(id) {
	if (app.tasksToLoad==null) return false;
	return app.tasksToLoad.includes(id);
}


function inGroupsTaskList(id) {
	if (!app.group?.tasks) return false;
	for (let i=0; i<app.group.tasks.length; i++) {
		if (app.group.tasks[i].id==id) return true;
	}
	return false;
}



function ifTaskInGroup(task)
{
	if (!task.groups) return false;

	for (let i=0; i<task.groups.length; i++) {
		if (task.groups[i].slug==app.groupSlug) return true;
	}
	return false;
}



tasks.loadTasks();

</script>


<style>
.reader__scan_region img {
	@apply mx-auto;
}
</style>

<template>

	<div class="flex w-full sm:max-w-[600px] mx-auto overflow-hidden">
		<div class=" flex p-2 sm:py-4 sm:px-8 flex-col w-full overflow-hidden">
			<div class="frosted-panel flex flex-col overflow-hidden">

				<div class="flex items-center px-4 pt-4 pb-2 gap-4">

					<button title="close" class="round-button" @click="app.show=app.showBack.pop()">
						<ArrowLeftIcon class="w-6 h-6" />
					</button>

					<h2 class="menu-title flex items-center justify-center gap-2 flex-grow ">
						<ShareIcon class="size-6"></ShareIcon>
						{{ $t('tasks.tasks') }}
					</h2>

					<button title="close" class="round-button" @click="app.show=null">
						<XMarkIcon class="w-6 h-6" />
					</button>

				</div>


				<div class="flex flex-wrap gap-2 items-center justify-center mb-2" v-if="app.user">
					<button 
						class="border border-lime-600 dark:border-lime-500 py-1 px-2 rounded-lg cursor-pointer disabled:opacity-50 flex items-center gap-1"
						@click="tab = 'list'"
						:class="tab == 'list' ? 'bg-lime-600 dark:bg-lime-500 text-white dark:text-black' : 'hover:bg-gray-200 dark:hover:bg-gray-900'">
							List
					</button>
					<button 
						class="border border-lime-600 dark:border-lime-500 py-1 px-2 rounded-lg cursor-pointer disabled:opacity-50 flex items-center gap-1"
						@click="tab = 'qrscan'"
						:class="tab == 'qrscan' ? 'bg-lime-600 dark:bg-lime-500 text-white dark:text-black' : 'hover:bg-gray-200 dark:hover:bg-gray-900'">
							QR Code Scan
					</button>
					<button 
						class="border border-lime-600 dark:border-lime-500 py-1 px-2 rounded-lg cursor-pointer disabled:opacity-50 flex items-center gap-1"
						@click="tab = 'igcupload'"
						:class="tab == 'igcupload' ? 'bg-lime-600 dark:bg-lime-500 text-white dark:text-black' : 'hover:bg-gray-200 dark:hover:bg-gray-900'">
							CUP File Upload
					</button>
				</div>





				<!-- scan a QR code -->
				<div v-if="tab=='qrscan' && app.user" class="overflow-y-scroll">

					<TaskScanner @found="code => foundQR(code)"></TaskScanner>

				</div>




				<!-- upload a CUP file -->
				<div  v-if="tab=='igcupload' && app.user" class="flex flex-col overflow-y-scroll px-4 pb-4 pt-4 gap-4">

					<h3 class="menu-section-title">{{ $t('tasks.upload-cup-file') }}</h3>

					<div class="menu-section">

						<form v-on:submit.prevent="uploadTask" class="p-4 flex flex-wrap items-center gap-4">
							<input type="file" id="cupfile" name="cupfile" class="input rounded text-sm btn">
							<button v-if="!uploading" class="btn btn-primary ">
								{{ $t('app.upload') }} 
							</button>
							<LoadingHorizontal v-if="uploading" ></LoadingHorizontal>

						</form>

						<div class="px-4 pb-2 text-sm flex flex-col gap-1">
							<p>{{ $t('tasks.uploading-note') }}</p>

							<p>{{ $t('tasks.default-note') }}</p>
						</div>

					</div>

				</div>



				<!-- list tasks -->

				<div  class="flex flex-col overflow-y-scroll px-4 pb-4 pt-6 gap-4"  v-if="tab=='list'">



					<div  v-if="app.group && tasks.groupTasks().length>0">
							
						<h3 class="menu-section-title my-4">{{app.group.name}}</h3>

						<div class="menu-section flex flex-col gap-2 my-4 p-2">
							<div v-for="task in tasks.groupTasks()" :key="task.id"
								class="flex flex-wrap sm:flex-nowrap items-center gap-4 border-b border-b-gray-300 dark:border-b-gray-700 pb-4 sm:border-0" @click="tasks.toggleTask(task)">

								<ToggleSwitch :id="'grouptask' + task.id"  v-model="task.show"></ToggleSwitch>

								<div class="flex-grow">
									<div>{{task.name}}</div>
									<div class="text-sm text-gray-600 dark:text-gray-400">
										Uploaded {{renderTimeAgo(apiDateToJS(task.updated_at))}} ago on
										{{formatTimeFromJSDate(apiDateToJS(task.updated_at), true, false)}}
									</div>

									<button v-if="app.user && (app.user.id==app.group.user_id || app.user.level==1)" title="Remove from Group" @click.stop="task.removing=true; tasks.removeTaskFromGroup(task.id, app.group.slug)" class="round-button flex items-center gap-1 text-sm">
										<UserGroupIcon v-if="!task.removing" class="size-4" />
										<div v-if="!task.removing">
											Remove from {{app.group.name}}
										</div>
										<LoadingHorizontal class="my-1" v-if="task.removing"></LoadingHorizontal>
									</button>
								</div>
							</div>

						</div>

					</div>


					<div class="flex flex-col  px-4 pb-4 pt-4 gap-4 text-center" v-if="!app.user">

						<a href="/login">
							{{$t('tasks.login-required')}}
						</a>

					</div>



					<div v-if="tab=='list' && app.user">
						<h3 class="menu-section-title flex items-center gap-4 justify-center  my-4">
							{{ $t('tasks.your-tasks') }}
							<LoadingHorizontal v-if="tasks.loading>0"></LoadingHorizontal>
						</h3>

						<div class="menu-section">

							<div class="p-2 flex flex-col gap-4 text-center text-gray-600 dark:text-gray-400" v-if="tasks.myTasks().length==0">
								{{ $t('tasks.no-tasks-yet') }}
							</div>



							<div class="p-2 flex flex-col gap-4" v-if="tasks.myTasks().length>0">

								<div v-for="task in tasks.myTasks()" :key="task.id"
									class="flex flex-wrap sm:flex-nowrap items-center gap-4 border-b border-b-gray-300 dark:border-b-gray-700 pb-4 sm:border-0" @click="tasks.toggleTask(task)">

									<ToggleSwitch  :id="'task' + task.id"  v-model="task.show"></ToggleSwitch>

									<div class="flex-grow">
										<div>{{task.name}}</div>
										<div class="text-sm text-gray-600 dark:text-gray-400">
											Uploaded {{renderTimeAgo(apiDateToJS(task.updated_at))}} ago on
											{{formatTimeFromJSDate(apiDateToJS(task.updated_at), true, false)}}
										</div>

										<div v-if="app.group?.name && (app.user.id==app.group.user_id || app.user.level==1)">

											<button v-if="!ifTaskInGroup(task)" title="Add to Group" @click.stop="task.adding=true; tasks.addTaskToGroup(task.id, app.group.slug)" class="round-button flex items-center gap-1 text-sm">

												<UserGroupIcon v-if="!task.adding" class="size-4" />
												<div v-if="!task.adding">Add to {{app.group.name}}</div>

												<LoadingHorizontal class="my-1" v-if="task.adding"></LoadingHorizontal>
											</button>

										</div>


									</div>

									<button v-if="!task.deleting" :title="$t('app.delete')" @click.stop="tasks.deleteTask(task)" class="round-button">
										<TrashIcon class="size-4" />
									</button>
									<div v-if="task.deleting" class="flex justify-center">
										<LoadingHorizontal></LoadingHorizontal>
									</div>

								</div>
							</div>

						</div>
					</div>



				</div>



			</div>


		</div>
	</div>

</template>