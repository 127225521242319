<script setup>
import { ref, computed } from 'vue';
import boundingBoxes from '/data/bounding-boxes.json';
import { appStateStore } from "@/stores/AppStateStore.js";
import { settingsStore } from "@/stores/SettingsStore.js";
import { mapStyles } from "@/stores/ProMapStyles.js";

import { messagesStore } from "@/stores/MessagesStore.js";
const messages = messagesStore();

import ToggleSwitch from '@/PureComponents/ToggleSwitch.vue';

import { TrophyIcon, CogIcon, XMarkIcon, ArrowRightIcon, ArrowLeftIcon, LockClosedIcon, CheckIcon, ChevronDownIcon, ChevronRightIcon, DocumentDuplicateIcon } from "@heroicons/vue/24/outline";

import { useDrawCompTasks } from "@/Composables/useDrawCompTasks.js";
const drawTasks = useDrawCompTasks();

import { copyToClipboard, formatDateTime } from '../Helpers.js';

import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const app = appStateStore();
const settings = settingsStore();


function copyUrl(url) {
	copyToClipboard(url); 
	messages.success(t('app.copied-to-clipboard'));
}
</script>

<template>

	<div class="flex w-full sm:max-w-[600px] mx-auto overflow-hidden">
		<div class=" flex p-2 sm:px-8 sm:py-4 flex-col w-full overflow-hidden">
			<div class="frosted-panel flex flex-col overflow-hidden">

				<div class="flex items-center px-4 pt-4 pb-2 gap-4">

					<button title="close" class="round-button" @click="app.show=null">
						<XMarkIcon class="w-6 h-6" />
					</button>

					<h2 v-if="app.comp" class="menu-title flex-grow">
						<TrophyIcon class="w-6 h-6 min-w-8 opacity-60 inline mr-1" />{{ app.comp.name }}
					</h2>

					<button title="close" class="round-button opacity-0" @click="app.show=null">
						<XMarkIcon class="w-6 h-6" />
					</button>

				</div>



				<div class="flex flex-col overflow-y-scroll px-4 pb-4 pt-1 gap-4">

					<div v-if="app.comp?.start" class="text-center">
						{{formatDateTime(app.comp.start, false, false)}} 
						-
						{{formatDateTime(app.comp.end, false)}}
					</div>

					<div class="menu-section">

						<div v-if="app.comp?.classes?.length==0" 
						class="menu-row text-gray-600 dark:text-gray-400">
							{{ $t('contests-menu.no-classes') }}
						</div>

						<template 
								v-for="clss in app.comp.classes" :key="clss.id" >

							<div class="menu-row menu-row-clickable flex items-center cursor-pointer">

								<button  @click.stop="clss.showPilots=!clss.showPilots" class="py-2 pl-2 pr-0">
									<ChevronRightIcon v-if="!clss.showPilots" class="size-4"></ChevronRightIcon>
									<ChevronDownIcon  v-if="clss.showPilots" class="size-4"></ChevronDownIcon>
								</button>

								<label :for="'cl_' + clss.id" class="flex justify-between items-center flex-grow">


									<div class="flex items-center gap-2">

										<input type="checkbox" :id="'cl_' + clss.id" :value="true" v-model="clss.showTask" @change="drawTasks.showTasks()">

										{{clss.name}}
									</div>

								</label>

								<div @click.stop="clss.showPilots=!clss.showPilots" class="rounded-full bg-gray-700 text-white dark:bg-gray-300 dark:text-black  py-1 px-2 whitespace-nowrap text-sm">
									{{clss.competitors?.length}} 
									pilot<span v-show="clss.competitors?.length!==1">s</span>

								</div>

							</div>

							<div class="menu-row items-left  py-1 px-2 flex-col gap-2 " v-if="clss.showPilots">
								<div class="w-full flex gap-2 " v-for="competitor in clss.competitors" :key="competitor.id">
									<div class="w-10">{{competitor.comp_rego}}</div>
									<div>{{competitor.name}} {{competitor.name_second}}</div>
								</div>
							</div>


						</template>

					</div>

					<div class="menu-section">

						<label for="filterComp" class="menu-row menu-row-clickable ">
							<div>
								<input class="mr-1" type="checkbox" id="filterComp" :value="true" v-model="settings.filterComp" @change="app.restartLoop=true;"> {{ $t('contests-menu.only-show-competitors') }}
							</div>
						</label>


						<div @click="copyUrl(app.comp.url)" class="menu-row menu-row-clickable justify-between w-full cursor-pointer">

							<div class="flex flex-col gap-1 items-start whitespace-nowrap max-w-full overflow-hidden">
								<span>{{ $t('contests-menu.puretrack-link') }}</span>
								<span class="text-sm max-w-full text-gray-600 dark:text-gray-400 overflow-hidden  overflow-ellipsis">{{app.comp.url}}</span>
							</div>

							<button :title="$t('app.copy-to-clipboard')" class="round-button"  >
								<DocumentDuplicateIcon class="w-6 h-6" />
							</button>

						</div>

					</div>


					<a v-if="app.comp?.source_url && app.comp.source=='ssa'" class="menu-button menu-row-clickable" :href="app.comp.source_url" >
						{{$t('contests-menu.view-results-on-website')}}
					</a> 
					<a v-if="app.comp?.source_url && app.comp.source=='soaringspot'" class="menu-button menu-row-clickable" :href="'https://soaringspot.com' + app.comp.source_url" >
						{{$t('contests-menu.view-results-on-website')}}
					</a> 

				</div>



			</div>


		</div>
	</div>

</template>