<script setup>
import { ref, onMounted, watch } from 'vue';
import { settingsStore } from "@/stores/SettingsStore.js";
import { appStateStore } from "@/stores/AppStateStore.js";
import { pointsStore } from "@/stores/PointsStore.js";
import { pointTypesStore } from "@/stores/PointTypesStore.js";
import ToggleSwitch from '@/PureComponents/ToggleSwitch.vue';
import { useGroups } from "@/Composables/UseGroups.js";
import { sourcesStore } from "@/stores/SourcesStore.js";
import { useMapTools } from "@/Composables/MapTools.js";
import scrollIntoView from 'scroll-into-view-if-needed'

import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
TimeAgo.setDefaultLocale(en.locale)
TimeAgo.addLocale(en);

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);


import { pointShortName, renderHeight, getIconImage, renderTimeAgo, renderSpeed, renderName, renderDistance, renderBearing, apiDateToJS, formatTimeFromJSDate, formatStartTime } from '../Helpers.js';

import TrackIcon from "@/Icons/TrackIcon.vue";
import { ChevronDoubleDownIcon, ChevronDoubleUpIcon, LockClosedIcon, CogIcon, EllipsisHorizontalIcon, ChevronUpIcon, ChevronDownIcon, UserGroupIcon, ViewfinderCircleIcon, ExclamationTriangleIcon, MagnifyingGlassIcon } from "@heroicons/vue/24/outline";


const timeAgo = new TimeAgo('en');
const points  = pointsStore();
const app = appStateStore();
const settings = settingsStore();
const groups = useGroups();
const showRecent = ref(false);
const progressShowing = ref(false);
const pointTypes = pointTypesStore();
const sources  = sourcesStore();
const mapTools = useMapTools();

var subSelectedLocal=[];



function isSelected(key) {
	//const i=this.selectedTracks.map(e => e.key).indexOf(key);
	//return i>-1 ? true: false;
	if (app.selected==null) return false;
	return app.selected==key ? true : false;
}
function isSubSelected(key) {
	if (subSelectedLocal===null) return false;
	if (!subSelectedLocal?.length>0) return false;
	return subSelectedLocal.includes(key);
}
function isChecked(key) {
	if (app.selectedTracks===null) return false;
	if (!app.selectedTracks?.length>0) return false;
	return app.selectedTracks.includes(key);
}

function toggleFollow(key) {
	if (!settings.isFollowed(key)) settings.addFollowing(key);
	else settings.stopFollowing(key);
}

function loadAllTracks() {
	for (var i=0; i<points.withLabelsCount; i++) {
		if (points.withLabelsCount<app.maxTracks) {
			app.selectedTracks.push(points.points[i].key);
		} else {
			messages.error('Only ' + app.maxTracks + ' tracks can be opened at one time');
			return;
		}
	}
}

function toggleSelectedTrack(item) {
	if (app.selectedTracks.includes(item)) {
		app.removeSelectedTrack(item);
	} else {
		app.addSelectedTrack(item);
	}
}



function filteredPoints() {

	// if too many points, only show selected
	if (points.legendPoints.length>app.maxLegendPoints) {
		return points.legendPoints.filter(function(point) {
			if (settings.isFollowed(point.key) || isSelected(point.key) || groups.isInGroup(point.key)) return true;
			return false;
		});
	}

	// otherwise if we are hiding unidentified items, filter them out and show selected
	if (settings.legendHideUnknown) {
		return points.legendPoints.filter(function(point) {

			if (settings.isFollowed(point.key) || isSelected(point.key) || groups.isInGroup(point.key)) return true;

			if (settings.legendHideUnknown) {
				if (!point.unidentified) return true;
			}
			return false;

		});
	}

	// otherwise return all
	return points.legendPoints;
}



function filteredAndSortedPoints() {
	return filteredPoints().sort(function(x, y) {

		if (settings.selectedAtTop) {
			if (x.key==app.selected && y.key!=app.selected) return -1;
			if (y.key==app.selected && x.key!=app.selected) return 1;
		}

		if (settings.selectedAtTop) {
			if (app.selectedTracks.includes(x.key) && !app.selectedTracks.includes(y.key)) return -1;
			if (app.selectedTracks.includes(y.key) && !app.selectedTracks.includes(x.key)) return 1;
		}
		
		if ((subSelectedLocal.includes(x.key) && subSelectedLocal.includes(y.key)) 
			|| (!subSelectedLocal.includes(x.key) && !subSelectedLocal.includes(y.key))
			|| !settings.selectedAtTop) {

			if (settings.sortOrder=='desc') {
				var a=x;
				var b=y;
			} else {
				var a=y;
				var b=x;
			}

			let q = '';
			let r = '';

			switch (settings.sortBy) {
				case 'group':
					if (groups.isInGroup(a.key) > groups.isInGroup(b.key)) return -1;
					return 1;
					break;
				case 'track':
					if (app.selectedTracks.includes(a.key) > app.selectedTracks.includes(b.key)) return -1;
					return 1;
					break;
				case 'follow':
					if (settings.isFollowed(a.key) > settings.isFollowed(b.key)) return -1;
					return 1;
					break;
				case 'label': 
					if (pointShortName(a, 20, settings.shortenAircraftRegistrations) < pointShortName(b, 20, settings.shortenAircraftRegistrations)) return -1;
					if (pointShortName(a, 20, settings.shortenAircraftRegistrations) > pointShortName(b, 20, settings.shortenAircraftRegistrations)) return 1;
					break;
				case 'class':
					if (a.comp_class) q = a.comp_class;
					if (b.comp_class) r = b.comp_class;
					if (q < r) return -1;
					if (q > r) return 1;
					break; 
				case 'name':
					if (a.comp_name) q = a.comp_name;
					if (b.comp_name) r = b.comp_name;
					if (q < r) return -1;
					return 1;
					break;
				case 'callsign':
					if (a.callsign) q = a.callsign;
					if (b.callsign) r = b.callsign;
					if (q < r) return -1;
					return 1;
					break;
				case 'alt': 
					if (a.alt < b.alt) return -1;
					return 1;
					break;
				case 'agl': 
					if (a.agl < b.agl) return -1;
					return 1;
					break;
				case 'climb': 
					if (!a.thermal_climb_rate) q=0; else q=a.thermal_climb_rate;
					if (!b.thermal_climb_rate) r=0; else r=b.thermal_climb_rate;
					//console.log(r + ' ' + q);
					if (q < r) return -1;
					return 1;
					break;
				case 'lastseen': 
				case 'age': 
					if (a.stamp > b.stamp) return -1;
					if (a.stamp < b.stamp) return 1;
					break;
				case 'target': 
					if (a.targetDistance > b.targetDistance) return -1;
					if (a.targetDistance < b.targetDistance) return 1;
					break;
				case 'bearing': 
					if (a.targetBearing > b.targetBearing) return -1;
					if (a.targetBearing < b.targetBearing) return 1;
					break;
				case 'src': 
					if (a.source_id > b.source_id) return -1;
					if (a.source_id < b.source_id) return 1;
					break;
				case 'start': 
					if (a.start_ts) q = a.start_ts;
					if (b.start_ts) r = b.start_ts;
					if (q < r) return -1;
					return 1;
					break;
				case 'task-speed': 
					if (a.task_speed) q = a.task_speed;
					if (b.task_speed) r = b.task_speed;
					if (q < r) return -1;
					return 1;
					break;
				case 'task-leg': 
					if (a.task_leg) q = a.task_leg;
					if (a.task_leg=='F') q = 99;
					if (b.task_leg) r = b.task_leg;
					if (b.task_leg=='F') r = 99;
					if (q < r) return -1;
					return 1;
					break;
			}
		}

		if (settings.selectedAtTop) {

			if (subSelectedLocal.includes(x.key) && !subSelectedLocal.includes(y.key)) return -1;
			if (subSelectedLocal.includes(y.key) && !subSelectedLocal.includes(x.key)) return 1;
		}
		
		return 0;
	})
}


watch(() => app.selected, (currentValue, oldValue) => {
	// console.log('App.selected watch called in legend sidebar');
	// console.log(currentValue);
	if (!currentValue) return;

	// get ref of selected item
	setTimeout(function () {
		const node = document.getElementById(currentValue);
		if (node) scrollIntoView(node, { behavior: 'smooth', scrollMode: 'if-needed' });
	}, 100);
});


function toggleSort(name) {
	if (settings.sortBy==name) {
		settings.sortOrder=='desc' ? settings.sortOrder='asc' : settings.sortOrder='desc';
	}
	settings.sortBy=name;
}



function hideRecent() {
	setTimeout(function() { showRecent.value=false }, 100)
}



</script>

<template><div>

	<table class="w-full" v-show="!showRecent">
		<tr>
			<th v-show="groups.group.value" class="font-normal text-left text-gray-600 dark:text-gray-400 text-sm px-0 content-center">

				<button class="flex items-center gap-1 justify-center mx-auto p-1" @click="toggleSort('group')">
					<div v-show="settings.sortBy!=='group'" class="size-2 mx-2 rounded-full bg-gray-400 dark:bg-gray-600 " />
					<ChevronUpIcon v-show="settings.sortOrder=='desc' && settings.sortBy=='group'" class="size-4" />
					<ChevronDownIcon v-show="settings.sortOrder=='asc' && settings.sortBy=='group'" class="size-4" />
				</button>

			</th>
			<th v-show="settings.legendTrack" class="font-normal text-left text-gray-600 dark:text-gray-400 text-sm px-0 content-center">

				<button class="flex items-center gap-1 justify-center mx-auto" @click="toggleSort('track')">
					<div v-show="settings.sortBy!=='track'" class="size-2 mx-2 rounded-full bg-gray-400 dark:bg-gray-600 "></div>
					<ChevronUpIcon v-show="settings.sortOrder=='desc' && settings.sortBy=='track'" class="size-4" />
					<ChevronDownIcon v-show="settings.sortOrder=='asc' && settings.sortBy=='track'" class="size-4" />
				</button>


			</th>
			<th v-show="settings.legendFollow" class="font-normal text-left text-gray-600 dark:text-gray-400 text-sm px-0 content-center">

				<button class="flex items-center gap-1 justify-center mx-auto" @click="toggleSort('follow')">
					<div v-show="settings.sortBy!=='follow'" class="size-2 mx-2 rounded-full bg-gray-400 dark:bg-gray-600 "></div>
					<ChevronUpIcon v-show="settings.sortOrder=='desc' && settings.sortBy=='follow'" class="size-4" />
					<ChevronDownIcon v-show="settings.sortOrder=='asc' && settings.sortBy=='follow'" class="size-4" />
				</button>
			</th>
			<th class="font-normal text-left text-gray-600 dark:text-gray-400 text-sm px-2">
				<!-- icon -->
			</th>
			<th v-show="settings.legendLabel" class="font-normal text-left text-gray-600 dark:text-gray-400 text-sm px-1">
				
				<button :title="$t('legend.toggle-size')" class="flex items-center gap-1" @click="toggleSort('label')">
					<span v-show="settings.legendLabelSize>3">{{$t('legend.label')}}</span>
					<span v-show="settings.legendLabelSize<=3">L</span>
					<ChevronUpIcon v-show="settings.sortOrder=='desc' && settings.sortBy=='label'" class="size-4" />
					<ChevronDownIcon v-show="settings.sortOrder=='asc' && settings.sortBy=='label'" class="size-4" />
				</button>
			
			</th>
			<th v-show="settings.legendCallsign"  class="font-normal text-left text-gray-600 dark:text-gray-400 text-sm px-1">
				
				<button class="flex items-center gap-1" @click="toggleSort('callsign')">{{$t('labels-menu.callsign')}}
					<ChevronUpIcon v-show="settings.sortOrder=='desc' && settings.sortBy=='callsign'" class="size-4" />
					<ChevronDownIcon v-show="settings.sortOrder=='asc' && settings.sortBy=='callsign'" class="size-4" />
				</button>

			</th>
			<th v-show="settings.legendAlt" class="font-normal text-left text-gray-600 dark:text-gray-400 text-sm px-1">
				
				<button class="flex items-center gap-1" @click="toggleSort('alt')">{{$t('legend.alt')}}
					<ChevronUpIcon v-show="settings.sortOrder=='desc' && settings.sortBy=='alt'" class="size-4" />
					<ChevronDownIcon v-show="settings.sortOrder=='asc' && settings.sortBy=='alt'" class="size-4" />
				</button>

			</th>
			<th v-show="settings.legendAgl"  class="font-normal text-left text-gray-600 dark:text-gray-400 text-sm px-1">
				
				
				<button class="flex items-center gap-1" @click="toggleSort('agl')">{{$t('legend.agl')}}
					<ChevronUpIcon v-show="settings.sortOrder=='desc' && settings.sortBy=='agl'" class="size-4" />
					<ChevronDownIcon v-show="settings.sortOrder=='asc' && settings.sortBy=='agl'" class="size-4" />
				</button>

			</th>
			<th v-show="settings.legendAge" class="font-normal text-left text-gray-600 dark:text-gray-400 text-sm px-1">
				
				<button class="flex items-center gap-1" @click="toggleSort('age')">{{$t('legend.age')}}
					<ChevronUpIcon v-show="settings.sortOrder=='desc' && settings.sortBy=='age'" class="size-4" />
					<ChevronDownIcon v-show="settings.sortOrder=='asc' && settings.sortBy=='age'" class="size-4" />
				</button>

			</th>
			<th v-show="settings.legendGroundSpeed" class="font-normal text-left text-gray-600 dark:text-gray-400 text-sm px-1">
				
				<button class="flex items-center gap-1" @click="toggleSort('groundspeed')">{{$t('legend.speed')}}
					<ChevronUpIcon v-show="settings.sortOrder=='desc' && settings.sortBy=='groundspeed'" class="size-4" />
					<ChevronDownIcon v-show="settings.sortOrder=='asc' && settings.sortBy=='groundspeed'" class="size-4" />
				</button>

			</th>
			<th v-show="settings.legendClimb" v-if="app.subscribed && settings.legendShowThermalClimbRate" class="font-normal text-left text-gray-600 dark:text-gray-400 text-sm px-1">
				
				
				<button class="flex items-center gap-1" @click="toggleSort('climb')">{{$t('legend.climb')}}
					<ChevronUpIcon v-show="settings.sortOrder=='desc' && settings.sortBy=='climb'" class="size-4" />
					<ChevronDownIcon v-show="settings.sortOrder=='asc' && settings.sortBy=='climb'" class="size-4" />
				</button>

			</th>
			<th v-show="settings.legendName"  class="font-normal text-left text-gray-600 dark:text-gray-400 text-sm px-1">
				
				
				<button class="flex items-center gap-1" @click="toggleSort('name')">{{$t('legend.name')}}
					<ChevronUpIcon v-show="settings.sortOrder=='desc' && settings.sortBy=='name'" class="size-4" />
					<ChevronDownIcon v-show="settings.sortOrder=='asc' && settings.sortBy=='name'" class="size-4" />
				</button>

			</th>
			<th v-show="settings.legendClass"  class="font-normal text-left text-gray-600 dark:text-gray-400 text-sm px-1">
				
				
				<button class="flex items-center gap-1" @click="toggleSort('class')">{{$t('legend.class')}}
					<ChevronUpIcon v-show="settings.sortOrder=='desc' && settings.sortBy=='class'" class="size-4" />
					<ChevronDownIcon v-show="settings.sortOrder=='asc' && settings.sortBy=='class'" class="size-4" />
				</button>

			</th>
			<th v-show="settings.legendSourceIcon"  class="font-normal text-left text-gray-600 dark:text-gray-400 text-sm px-1 whitespace-nowrap">

				<button class="flex items-center gap-1 mx-auto" @click="toggleSort('src')">
					<span v-if="settings.sortBy!='src'">{{$t('legend.src')}}</span>
					<ChevronUpIcon v-show="settings.sortOrder=='desc' && settings.sortBy=='src'" class="size-4" />
					<ChevronDownIcon v-show="settings.sortOrder=='asc' && settings.sortBy=='src'" class="size-4" />
				</button>

			</th>
			<th v-show="settings.legendTrackerType" class="font-normal text-left text-gray-600 dark:text-gray-400 text-sm px-1 ">
				
				<button class="flex items-center gap-1" @click="toggleSort('src')">
					{{$t('legend.source')}}
					<ChevronUpIcon v-show="settings.sortOrder=='desc' && settings.sortBy=='src'" class="size-4" />
					<ChevronDownIcon v-show="settings.sortOrder=='asc' && settings.sortBy=='src'" class="size-4" />
				</button>

			</th>
			<th v-show="settings.legendTrackerID"  class="font-normal text-left text-gray-600 dark:text-gray-400 text-sm px-1 whitespace-nowrap">

				{{$t('legend.source-id')}}

			</th>

			<th v-if="settings.legendDistanceToTarget" class="font-normal text-left text-gray-600 dark:text-gray-400 text-sm px-1">
				
				<button class="flex items-center gap-1" @click="toggleSort('target')">{{$t('legend.target')}}
					<ChevronUpIcon v-show="settings.sortOrder=='desc' && settings.sortBy=='target'" class="size-4" />
					<ChevronDownIcon v-show="settings.sortOrder=='asc' && settings.sortBy=='target'" class="size-4" />
				</button>

			</th>

			<th v-if="settings.legendBearingToTarget" class="font-normal text-left text-gray-600 dark:text-gray-400 text-sm px-1">
				
				<button class="flex items-center gap-1" @click="toggleSort('bearing')">{{$t('legend.bearing')}}
					<ChevronUpIcon v-show="settings.sortOrder=='desc' && settings.sortBy=='bearing'" class="size-4" />
					<ChevronDownIcon v-show="settings.sortOrder=='asc' && settings.sortBy=='bearing'" class="size-4" />
				</button>

			</th>

			<th v-if="app.compStats && settings.legendStartTime" class="font-normal text-gray-600 dark:text-gray-400 text-sm px-1 text-center">

				<button class="flex items-center gap-1" @click="toggleSort('start')">
					{{ $t('legend.start') }}
					<ChevronUpIcon v-show="settings.sortOrder=='desc' && settings.sortBy=='start'" class="size-4" />
					<ChevronDownIcon v-show="settings.sortOrder=='asc' && settings.sortBy=='start'" class="size-4" />
				</button>
			</th>

			<th v-if="app.compStats && settings.legendTaskSpeed" class="font-normal text-gray-600 dark:text-gray-400 text-sm px-1 text-center">

				<button class="flex items-center gap-1" @click="toggleSort('task-speed')">
					{{ $t('legend.tspeed') }}
					<ChevronUpIcon v-show="settings.sortOrder=='desc' && settings.sortBy=='task-speed'" class="size-4" />
					<ChevronDownIcon v-show="settings.sortOrder=='asc' && settings.sortBy=='task-speed'" class="size-4" />
				</button>
			</th>

			<th v-if="app.compStats && settings.legendTaskLeg" class="font-normal text-gray-600 dark:text-gray-400 text-sm px-1 text-center">

				<button class="flex items-center gap-1" @click="toggleSort('task-leg')">
					{{ $t('legend.leg') }}
					<ChevronUpIcon v-show="settings.sortOrder=='desc' && settings.sortBy=='task-leg'" class="size-4" />
					<ChevronDownIcon v-show="settings.sortOrder=='asc' && settings.sortBy=='task-leg'" class="size-4" />
				</button>
			</th>

		</tr>
		<template v-for="point in filteredAndSortedPoints()" 
			:key="point.key">

			<tr :id="point.key" 
				:class="[ app.selected == point.key ? 
					' bg-gray-700 text-white dark:bg-gray-300 dark:text-black hover:bg-black hover:text-white dark:hover:bg-gray-300 dark:hover:text-black' 
					: 'hover:bg-gray-300 dark:hover:bg-gray-900' ]">

				<td class="px-1 py-1" v-show="groups.group.value">
				
					<button
						class="mx-auto"
						:title="[groups.isInGroup(point.key) ? $t('legend.leave-group') : $t('legend.join-group')]"
						:class="[groups.isInGroup(point.key) ? 'legend-button-selected' : 'legend-button']"
						@click="groups.toggleGroup(point)"
						>
						<spinner v-show="point.groupLoading" class="size-4" />
						<UserGroupIcon v-show="!point.groupLoading" class="size-4"></UserGroupIcon>
					</button>
				</td>

				<td class="px-1 py-1" v-show="settings.legendTrack">
				
					<button 
						class="mx-auto"
						:title="$t('legend.track')"
						:class="[app.selectedTracks.includes(point.key) ? 'legend-button-selected' : 'legend-button']"
						@click="toggleSelectedTrack(point.key)">
						<TrackIcon class="size-4" />
					</button>

				</td>

				<td class="px-1 py-1" v-show="settings.legendFollow">
					<button 
						class="mx-auto"
						:title="$t('legend.follow')"
						:class="[settings.isFollowed(point.key) ? 'legend-button-selected' : 'legend-button']"
						@click="toggleFollow(point.key)">
						<ViewfinderCircleIcon class="size-4" />
					</button>

				</td>

				<td class="px-1 py-1 w-6" @click="mapTools.clickPoint(point.key)">
					<div class="w-6 h-6 rounded-full" :style="{backgroundColor: '#' + point.colour}">
						<img class="realtive" :src="pointTypes.getIconImage(point.object_type)" width=32 height=32 :style="{ transform: 'rotate('+ point.course+'deg)'}">
					</div>
				</td>

				<td v-show="settings.legendLabel" class="px-2 py-1 whitespace-nowrap" @click="mapTools.clickPoint(point.key)">
						{{pointShortName(point, settings.legendLabelSize, settings.shortenAircraftRegistrations)}}
				</td>

				<td v-show="settings.legendCallsign" class="px-2 py-1" @click="mapTools.clickPoint(point.key)">
					{{point.callsign}}
				</td>
				<td v-show="settings.legendAlt" class="px-2 py-1" @click="mapTools.clickPoint(point.key)">
					{{renderHeight(point.alt, settings.altitudeUnits, 0, false)}}
				</td>
				
				<td v-show="settings.legendAgl" class="px-2 py-1" @click="mapTools.clickPoint(point.key)">
					{{renderHeight(point.agl, settings.altitudeUnits, 0, false)}}
				</td>

				<td v-show="settings.legendAge" class="px-2 py-1" @click="mapTools.clickPoint(point.key)">
					{{ renderTimeAgo(point.jsdate, app.currentTime) }}
				</td>

				<td v-show="settings.legendGroundSpeed" class="px-2 py-1 text-sm" @click="mapTools.clickPoint(point.key)">
					{{ renderSpeed(point.speed, settings.speedUnits) }}
				</td>

				<td v-show="settings.legendClimb" class="px-2 py-1" v-if="app.subscribed && settings.legendShowThermalClimbRate" @click="mapTools.clickPoint(point.key)">
					<span v-show="point.thermal_climb_rate">{{renderSpeed(point.thermal_climb_rate, settings.verticalUnits, 1)}}</span>
				</td>

				<td v-show="settings.legendName" 
					class="px-2 py-1 text-sm max-w-[80px] sm:max-w-[140px]  md:max-w-[200px] whitespace-nowrap overflow-hidden overflow-ellipsis"
					@click="mapTools.clickPoint(point.key)">
					<span v-if="point.comp_name">{{ renderName(point.comp_name) }}</span>

				</td>
				<td v-show="settings.legendClass" 
					class="px-2 py-1 text-sm max-w-[80px] sm:max-w-[140px] md:max-w-[200px]  whitespace-nowrap overflow-hidden overflow-ellipsis" 
					@click="mapTools.clickPoint(point.key)">
					<span v-if="point.comp_class">{{ renderName(point.comp_class) }}</span>
				</td>

				<td class="px-2" v-if="settings.legendSourceIcon" @click="mapTools.clickPoint(point.key)">

					<div class="dark:bg-white p-1 rounded-full min-w-7"><img :src="sources.getSource(point.source_id).icon" class="size-5" v-if="sources.getSource(point.source_id)?.icon"></div>
					
				</td>
				<td class="px-2 py-1 text-sm whitespace-nowrap" v-if="settings.legendTrackerType" @click="mapTools.clickPoint(point.key)">

					{{sources.getSource(point.source_id)?.name}}
					
				</td>

				<td class="px-2 py-1 text-sm" v-if="settings.legendTrackerID" @click="mapTools.clickPoint(point.key)">

					{{point.tracker_uid}}
					
				</td>

				<td @click="mapTools.clickPoint(point.key)" class="px-2 py-1 text-sm" v-if="settings.legendDistanceToTarget">

					{{renderDistance(point.targetDistance, settings.distanceUnits)}}
					
				</td>
				<td @click="mapTools.clickPoint(point.key)" class="px-2 py-1 text-sm" v-if="settings.legendBearingToTarget">

					{{renderBearing(point.targetBearing)}}
					
				</td>

				<td @click="mapTools.clickPoint(point.key)" v-if="app.compStats && settings.legendStartTime" class="px-2 py-1 text-sm text-center">
					<div v-if="point.start_ts">
						{{ formatStartTime(apiDateToJS(point.start_ts), point.timezone) }}
					</div>
					<div v-if="!point.start_ts" class="text-xs text-gray-600 dark:text-gray-400">
						DNS
					</div>
				</td>

				<td @click="mapTools.clickPoint(point.key)" v-if="app.compStats && settings.legendTaskSpeed" class="px-2 py-1 text-sm text-center">
					<div v-if="point.task_speed">
						{{ renderSpeed(point.task_speed, settings.speedUnits) }}
					</div>
					<div v-if="!point.task_speed" class="text-xs text-gray-600 dark:text-gray-400">
						N/A
					</div>
				</td>

				<td @click="mapTools.clickPoint(point.key)" v-if="app.compStats && settings.legendTaskLeg" class="px-2 py-1 text-sm text-center">
					<div v-if="point.task_leg">
						{{point.task_leg}}
					</div>
					<div v-if="!point.task_leg" class="text-xs text-gray-600 dark:text-gray-400">
						
					</div>
				</td>

			</tr>
		</template>
	</table>

	<div v-show="!showRecent">
		<div v-if="settings.onlyShowSelected" class="p-2 flex items-center gap-1 "  
			@click="settings.onlyShowSelected = !settings.onlyShowSelected">
			<ToggleSwitch :title="$t('settings-menu.only-show-selected')" id="only-show-selected" v-model="settings.onlyShowSelected"></ToggleSwitch>
			<label for="only-show-selected" class="flex-grow">{{ $t('settings-menu.only-show-selected') }}</label>
		</div>


		<div v-if="points.points.length==0"
			class="px-2 py-2 text-gray-600 dark:text-gray-400 text-sm text-center">
			{{$t('app.no-results')}}
		</div>

		<div v-if="points.points.length > app.maxLegendPoints"
			class="px-2 py-2 text-gray-600 dark:text-gray-400 text-sm text-center flex items-center gap-1 justify-center">
			<ExclamationTriangleIcon class="w-4 h-4"></ExclamationTriangleIcon> 
			<div>{{$t('legend.too-many-to-list')}}</div>
		</div>
	</div>


</div></template>