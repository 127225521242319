<template>
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" stroke="currentColor" stroke-width="1.5" fill="none" viewBox="0 0 25 25" class="w-4 h-4">
	<circle stroke-linecap="round" stroke-linejoin="round" cx="2.91" cy="21.79" r="2.16"/>
	<circle stroke-linecap="round" stroke-linejoin="round" cx="22.09" cy="3.21" r="2.16"/>
	<circle stroke-linecap="round" stroke-linejoin="round" cx="8.48" cy="13.85" r="2.16"/>
	<circle stroke-linecap="round" stroke-linejoin="round" cx="18.41" cy="11.75" r="2.16"/>
	<line stroke-linecap="round" stroke-linejoin="round" x1="4" y1="20" x2="7" y2="15"/>
	<line stroke-linecap="round" stroke-linejoin="round" x1="11" y1="13" x2="16" y2="12"/>
	<line stroke-linecap="round" stroke-linejoin="round" x1="19" y1="10" x2="21" y2="5"/>
</svg>
</template>
