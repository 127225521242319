<script setup>

import { ref, watch } from 'vue';

import { settingsStore } from "@/stores/SettingsStore.js";
const settings = settingsStore();

import { useContestManager } from "@/Composables/UseContestManager.js";
const { currentContest, tasksData, clearContest, classesList, loadingContest, loadingClasses, addLatestTasks, pilots, filterNonContestPilots, classPilots } = useContestManager();

import { XMarkIcon, TrophyIcon, ChevronDoubleLeftIcon,  ChevronDoubleRightIcon, FunnelIcon, Bars3Icon } from '@heroicons/vue/24/outline';
import { appStateStore } from "@/stores/AppStateStore.js";
const app = appStateStore();

const open = ref(true);

</script>

<template>
	<button v-if="currentContest || loadingContest" @click="app.show='soaringspot-menu'" 
	:title="$t('contests-menu.contest-button')"
	class="ui-pill pl-1 pr-1 py-1 self-center flex items-center gap-2 w-full text-white bg-orange-700">

		<button href="/" :title="$t('group-menu.close-group')"
			class="pill-button flex-shrink-0"
			@click.stop="clearContest();">
			<XMarkIcon class="size-4 text-orange-700" />
		</button>

		<TrophyIcon class="w-5 h-5 min-w-5 opacity-50" />

		<span v-if="currentContest" class="whitespace-nowrap overflow-hidden overflow-ellipsis flex-grow flex justify-center" v-show="open">
			<div class="overflow-hidden overflow-ellipsis max-w-[300px]">{{currentContest.name}}</div>
		</span>

		<div v-if="open && loadingContest>0" class="flex items-center gap-2 mx-auto px-2">
			<LoadingHorizontal ></LoadingHorizontal>
		</div>

		<button  :title="$t('contests-menu.filter-to-contest')" 
			class=" pill-button"
			:class="[filterNonContestPilots ? 'pill-button-selected' : '']" 
			@click.stop="filterNonContestPilots=!filterNonContestPilots; app.restartLoop=true;">
			<FunnelIcon class="size-4 text-orange-700"  />
		</button>

		<button :title="$t('app.menu')"
			class="pill-button flex-shrink-0"
			@click="app.show='soaringspot-menu'">
			<Bars3Icon class="size-4 text-orange-700" />
		</button>


	</button>

</template>
