<script setup>
import { ref, computed } from 'vue';

import { appStateStore } from "@/stores/AppStateStore.js";
import { settingsStore } from "@/stores/SettingsStore.js";
import { useGroups } from "@/Composables/UseGroups.js";
const groups = useGroups();

import { CogIcon, XMarkIcon, ArrowRightIcon, ArrowLeftIcon, LockClosedIcon, CheckIcon, TrophyIcon, UserGroupIcon, ShareIcon, StarIcon, WindowIcon, UserCircleIcon, PlusCircleIcon } from "@heroicons/vue/24/outline";

import { renderTimeAgo, renderTimeAgoFull, formatDateTime, apiDateToJS, getDaysToGo } from '../Helpers.js';

const app = appStateStore();
const settings = settingsStore();

var loadingComps = ref(0);
var comps = ref([]); // new comps list
var posts = ref([]); // new comps list

function loadNews() {
	window.axios.get('/api/posts?limit=3').then(function (response) {
		posts.value = response.data.posts;
	}).catch(error => {
		console.log(error);
	});
}

function openComp(comp) {
	app.compSlug = comp.slug;
	app.show=null;
	settings.sideBarPage='list';
}

function fetchFeaturedComps() {

	loadingComps.value++;

	window.axios.get('/api/comps/featured').then(function (response) {
		comps.value = response.data.data;
		//console.log(comps.value);

		loadingComps.value--;

		//comps.value.inprogress.show=true;
	}).catch(error => {
		console.log(error.errors);
		loadingComps.value--;
	});

}

fetchFeaturedComps();
loadNews();

</script>

<template>

<div class="flex flex-col gap-2 pt-2">

	<div class="flex justify-center gap-2 mx-2">
		<div class="flex gap-2 w-fit items-center">
			<img src="/images/puretrack-logo.png" width="20">
			<h1 class="text-xl text-gray-600 dark:text-gray-200"><span class="font-bold">Pure</span>Track<span class="font-bold">.io</span></h1>
		</div>

	</div>

	<div class="flex gap-2 px-2">

		<div class="flex flex-1 justify-center items-center gap-2 p-2 rounded-md text-sm bg-white dark:bg-black shadow-lg hover:bg-gray-300 dark:hover:bg-gray-700 cursor-pointer"
		@click="app.show='load-contests-menu'">
			<TrophyIcon class="size-5 text-gray-500"></TrophyIcon> {{ $t('puretrack-menu.contests') }}
		</div>

		<div class="flex flex-1 justify-center items-center gap-2 p-2 rounded-md text-sm bg-white dark:bg-black shadow-lg hover:bg-gray-300 dark:hover:bg-gray-700 cursor-pointer"
		@click="app.show='load-groups-menu'">
			<UserGroupIcon class="size-5 text-gray-500"></UserGroupIcon> {{ $t('puretrack-menu.groups') }}
		</div>

		<div class="flex flex-1 justify-center items-center gap-2 p-2 rounded-md text-sm bg-white dark:bg-black shadow-lg hover:bg-gray-300 dark:hover:bg-gray-700 cursor-pointer"
		@click="app.show='tasks-menu'">
			<ShareIcon class="size-5 text-gray-500"></ShareIcon> {{ $t('tasks.tasks') }}
		</div>


	</div>

	<div class="mx-2 flex flex-col gap-1 overflow-clip" v-if="comps.length>0">
		<!-- <div class="text-sm text-gray-600 dark:text-gray-400 text-center">
			{{ $t('contests-menu.featured-contests') }}
		</div> -->
		<div v-for="comp in comps" :key="comp.id" 
		class="flex items-center gap-2 p-2 rounded-md  bg-orange-700 hover:bg-orange-800 text-white cursor-pointer text-sm justify-center " 
		@click="openComp(comp)">
			<TrophyIcon class="size-5 flex-grow-0 opacity-50" v-if="comp.feature"></TrophyIcon>
			<div class="whitespace-nowrap overflow-clip overflow-ellipsis">{{comp.name}}</div>

			<div v-if="getDaysToGo(apiDateToJS(comp.start))<=0 && getDaysToGo(apiDateToJS(comp.end))>=0" class="text-xs font-bold text-orange-700 bg-white bg-opacity-50 rounded-full py-1 px-2 whitespace-nowrap w-fit">
				{{getDaysToGo(apiDateToJS(comp.end)) }} day<span v-show="getDaysToGo(apiDateToJS(comp.end))!=1">s</span> remain
			</div>

		</div>
	</div>

	<div class="flex gap-2 px-2">

		<a href="/trackers" class="flex flex-1  justify-center items-center gap-2 p-2 rounded-md text-sm bg-white dark:bg-black shadow-lg hover:bg-gray-300 dark:hover:bg-gray-700 cursor-pointer"
		@click="app.show='load-contests-menu'">
			<PlusCircleIcon class="size-5 text-gray-500"></PlusCircleIcon> {{ $t('app.add-tracker') }}
		</a>
		<a href="/dashboard" class="flex flex-1 justify-center items-center gap-2 p-2 rounded-md text-sm bg-white dark:bg-black shadow-lg hover:bg-gray-300 dark:hover:bg-gray-700 cursor-pointer">
			<WindowIcon class="size-5 text-gray-500"></WindowIcon> {{ $t('user.dashboard') }}
		</a>

	</div>


	<div class="flex flex-col gap-2 flex-grow px-2" v-if="posts">

		<h2 class="font-bold">PureTrack News</h2>

		<div class="flex flex-col gap-2">
			<div v-for="post in posts" :key="post.id" class="flex gap-2 bg-white hover:bg-gray-200 dark:bg-gray-900 dark:hover:bg-gray-700 rounded-lg shadow-lg">

				<a :href="'/news/' + post.slug">
					<img v-if="post.feature_image" 
					:src="'/storage/images/'+ post.id +'/'+post.feature_image.filename+'-900.'+ post.feature_image.extension" 
					class="my-0 rounded-l h-auto flex-grow-0"
					width="100">
				</a>

				<div class="w-[100px] rounded-l-lg border border-gray-300" v-if="!post.feature_image"></div>

				<div class="flex flex-col gap-1 p-1">
					<a :href="'/news/' + post.slug" class="font-bold link max-w-[270px]">{{post.title}}</a>

					<a v-if="post.caption" :href="'/news/' + post.slug" class="max-w-[270px] text-sm" v-html="post.caption"></a>

					<a :href="'/news/' + post.slug" class="text-gray-600 dark:text-gray-400 text-sm">{{formatDateTime(post.created_at, false)}}</a>
				</div>
			</div>
		</div>

	</div>

	<div class="flex gap-2 px-2 mb-4">

		<a href="/news" class="flex flex-1 justify-center items-center text-center gap-2 p-2 rounded-md text-sm bg-white dark:bg-black shadow-lg hover:bg-gray-300 dark:hover:bg-gray-700 cursor-pointer">
			{{ $t('app.more-news') }}
		</a>
		
		<a href="/release-notes" class="flex flex-1 justify-center items-center text-center gap-2 p-2 rounded-md text-sm bg-white dark:bg-black shadow-lg hover:bg-gray-300 dark:hover:bg-gray-700 cursor-pointer">
			{{ $t('app.release-notes') }}
		</a>
		
		<a href="https://www.facebook.com/puretrack.io" class="flex flex-1 justify-center items-center text-center gap-2 p-2 rounded-md text-sm bg-white dark:bg-black shadow-lg hover:bg-gray-300 dark:hover:bg-gray-700 cursor-pointer">
			Facebook
		</a>

	</div>


</div>

</template>