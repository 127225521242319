<script setup>
import { ref, computed } from 'vue';
import { appStateStore } from "@/stores/AppStateStore.js";
import { settingsStore } from "@/stores/SettingsStore.js";
import { mapStyles } from "@/stores/ProMapStyles.js";

import { messagesStore } from "@/stores/MessagesStore.js";
const messages = messagesStore();

import { pointsStore } from "@/stores/PointsStore.js";
const points  = pointsStore();

import { useI18n } from 'vue-i18n'
const { t } = useI18n()

import ToggleSwitch from '@/PureComponents/ToggleSwitch.vue';

import { CogIcon, XMarkIcon, ArrowRightIcon, ArrowLeftIcon, LockClosedIcon, CheckIcon, ArrowUpOnSquareIcon, DocumentDuplicateIcon, ArrowRightStartOnRectangleIcon, BellAlertIcon, PencilIcon } from "@heroicons/vue/24/outline";

import { renderAlertDescription } from '../Helpers.js';


const app = appStateStore();
const settings = settingsStore();


function createAlert(selected, target) {
	console.log(target);
	let data = {
		'key': selected,
		'target_id': target?.target_id
	};
	axios.post('/api/alerts', data).then(function(response) {
		let alert = response.data;
		console.log(response.data);

		messages.success('Alert Created');
		console.log('Alert Created');
		setTimeout(function(){ 
			window.location.href = "/alerts/" + alert.id;
		}, 1000);
	}).catch(function (error) {
		messages.error('Failed to create alert');
		console.log('failed to create alert');
	});
}

</script>

<template>

	<div class="flex w-full sm:max-w-[600px] mx-auto overflow-hidden">
		<div class=" flex p-2 sm:px-8 sm:py-4 flex-col w-full overflow-hidden">
			<div class="frosted-panel flex flex-col overflow-hidden">

				<div class="flex items-center px-4 pt-4 pb-2 gap-4">

					<button :title="$t('app.back')" class="round-button" @click="app.show=null">
						<XMarkIcon class="w-6 h-6" />
					</button>

					<h2 class="menu-title flex-grow ">
						<BellAlertIcon class="inline size-6" />
						{{ $t('alerts.alerts') }} 
					</h2>

					<button title="close" class="round-button opacity-0" @click="app.show=null">
						<XMarkIcon class="w-6 h-6" />
					</button>

				</div>


				<div class="flex flex-col overflow-y-scroll px-4 pb-4 pt-6">
					<div class="flex flex-col gap-6 w-full">


						<div class="menu-section">

							<div class="menu-row text-gray-600 dark:text-gray-400" v-if="app.alerts.length==0">
								<BellAlertIcon class="opacity-50 size-5" /> {{$t('alerts.no-alerts-configured')}}


							</div>

							<a v-if="app.alerts.length>0"
								:key="alert.id"
								v-for="alert in app.alerts"
								class="menu-row menu-row-clickable flex " 
								:href="'/alerts/' + alert.id"
								>

								<BellAlertIcon class="opacity-50 size-5" />

								<div>#{{alert.id}}</div>

								<div class="flex flex-col gap-1 flex-grow">
									<div v-for="description in alert.description" :key="description"
									class="text-sm overflow-hidden overflow-ellipsis whitespace-nowrapw-full">{{description}}</div>
								</div>

								<div v-if="app.admin" class="ml-2">{{alert.user_id}}</div>

								<a :href="'/alerts/' + alert.id" :title="$t('app.edit')" class="round-button">
									<PencilIcon class="w-5 h-5" />
								</a>
							</a>

						</div>


						<div class="menu-section">

							<button 
								v-if="app.subscribed"
								class="menu-row menu-row-clickable w-full" 
								@click="createAlert(app.selected, points.selected)"
								:title="$t('alerts.create-alert')">
								{{$t('alerts.create-alert')}}
							</button>


							<div class="menu-row  w-full" v-if="!app.subscribed">

								<div class="opacity-50 flex-grow">{{$t('alerts.create-alert')}}</div>

								<a href="/upgrade" v-if="!app.fromAppStore" class="flex gap-2 text-sm items-center">
									{{$t('app.upgrade-to-pro')}}
									
									<button title="Upgrade to Pro" class="round-button">
										<LockClosedIcon class="h-4 w-4" />
									</button>

								</a>

								<div class="round-button" v-if="app.fromAppStore">
									<LockClosedIcon class="h-4 w-4"  />
								</div>
							</div>

						</div>


					</div>

				</div>

			</div>


		</div>
	</div>

</template>