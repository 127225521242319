<script setup>
import { ref, computed, onMounted } from 'vue';
import boundingBoxes from '/data/bounding-boxes.json';
import { appStateStore } from "@/stores/AppStateStore.js";
import { settingsStore } from "@/stores/SettingsStore.js";
import { mapStyles } from "@/stores/ProMapStyles.js";
import { messagesStore } from "@/stores/MessagesStore.js";
import { skySightStore } from "@/stores/SkySightStore.js";

import { useGroups } from "@/Composables/UseGroups.js";
const groups = useGroups();

import ToggleSwitch from '@/PureComponents/ToggleSwitch.vue';

import { CogIcon, XMarkIcon, ArrowRightIcon, ArrowLeftIcon, LockClosedIcon, CheckIcon } from "@heroicons/vue/24/outline";

const app = appStateStore();
const settings = settingsStore();
const messages = messagesStore();
const skysight = skySightStore();

const username = ref('');
const password = ref('');

function logout() {
	
	window.axios.post('/api/skysight/logout', {
		username: username.value
	}).then(function (response) {
		messages.success('Logged out');
		app.skysightLoggedIn = false;
	}).catch(error => {
		messages.error('Logout failed');
		console.log(error);
	});
}

function login() {
	
	if (username.value.length==0) {
		messages.error('A username is required');
		return;
	}
	if (password.value.length==0) {
		messages.error('A password is required');
		return;
	}

	window.axios.post('/api/skysight/login', {
		username: username.value,
		password: password.value
	}).then(function (response) {
		console.log(response.data);
		let data = response.data;
		if (data.token) {
			app.skysightLoggedIn = true;
			messages.success('Logged into SkySight for 30 days');
		} else {
			messages.error('Login failed');
		}
	}).catch(error => {
		messages.error('Login failed');
		console.log(error);
	});
}

onMounted(() => {
	if (document.querySelector('meta[name="skysight-username"]')) {
		username.value = document.querySelector('meta[name="skysight-username"]').content;
	}
})


</script>

<template>

	<div class="flex w-full sm:max-w-[600px] mx-auto overflow-hidden">
		<div class=" flex p-2 sm:py-4 sm:px-8 flex-col w-full overflow-hidden">
			<div class="frosted-panel flex flex-col overflow-hidden">

				<div class="flex items-center px-4 pt-4 pb-2 gap-4">

					<button :title="$t('app.back')" class="round-button" @click="app.show=app.showBack.pop()">
						<ArrowLeftIcon class="w-6 h-6" />
					</button>

					<h2 class="menu-title flex-grow flex justify-center items-center gap-4">
						
						<img src="/images/source-icons/skysight.png" class="h-auto w-8 "> 
						SkySight
					</h2>

					<button title="close" class="round-button" @click="app.show=null">
						<XMarkIcon class="w-6 h-6" />
					</button>

				</div>


				<div class="flex flex-col overflow-y-scroll px-4 pb-4 pt-6 gap-4">

					<div class="menu-section p-4">

						<p class="mb-4">Need a SkySight subscription? Use the coupon code <strong>PUREGLIDE</strong> for 10% discount off your first year.</p>

						<a class="link" href="https://skysight.io/register/">Register SkySight</a>

					</div>


					<div class="menu-section p-4">

						<div class="flex flex-col gap-4" v-if="!app.skysightLoggedIn">
							
							<div class="flex flex-col gap-2">
								<div>SkySight Username</div>
								<input class="input w-full" type="text" v-model="username">
							</div>
							<div class="flex flex-col gap-2">
								<div>SkySight Password</div>
								<input class="input w-full" type="password" v-model="password">
							</div>

							<button class="btn w-fit" @click="login()">Login</button>

						</div>

						<div class="flex flex-col gap-4" v-if="app.skysightLoggedIn">
							<div>
								Logged in as <strong class="">{{username}}</strong>
							</div>

							<button class="btn w-fit" @click="logout()">Logout</button>
						</div>

					</div>


					<div class="menu-section p-4">
						<div class="flex gap-4 items-center">
							<label for="skysightOpacity">Region</label>

							<select name="skySightRegion" id="skySightRegion" class="input" v-model="settings.skySightRegion">
								<option :value="null">Auto</option>
								<option type="text" v-for="region in skysight.regions"
								:value="region.id" :key="region.id">
									{{region.name}}
								</option>
							</select>
						</div>
					</div>


				</div>


			</div>


		</div>
	</div>

</template>