<script setup>
import { ref, onMounted, watch } from 'vue';

import { settingsStore } from "@/stores/SettingsStore.js";
import { appStateStore } from "@/stores/AppStateStore.js";
import { pointsStore } from "@/stores/PointsStore.js";
import { pointTypesStore } from "@/stores/PointTypesStore.js";

const app = appStateStore();
const settings = settingsStore();

const selectedAircraftDetails = ref(null);
const loadingAircraftDetails = ref(false);
const selectedAircraftImage = ref(null);
const selectedAircraftLink = ref(null);
const selectedAircraftPhotographer = ref(null);
const loadingAircraftImage = ref(false);
const loadingTargetDetails = ref(false);
const pointTypes  = pointTypesStore();

import { XMarkIcon, PhotoIcon, LockClosedIcon } from "@heroicons/vue/24/outline";

const props = defineProps(['point']);


watch(() => props.point.rego, (currentValue, oldValue) => {
	//console.log('rego changed');
	getAircraft();
});


onMounted(() => {
	getAircraft();
});

function getAircraft() {

	selectedAircraftDetails.value = null;
	selectedAircraftImage.value = null;

	// not an aircraft
	if (!props.point.rego) return;

	loadingAircraftDetails.value = true;
	if (app.subscribed) loadingAircraftImage.value = true;

	axios.get('/api/aircraft/' + props.point.rego)
	.then(function(response) {
		console.log('fetched aircraft data');
		console.log(response.data.data);
		selectedAircraftDetails.value = response.data.data;
		loadingAircraftDetails.value = false;
	}).catch(function (error) {
		loadingAircraftDetails.value = false;
	});


	var image_details = null;

	axios.get('/api/aircraft/image/' + props.point.rego)
	.then(function(response) {
		loadingAircraftImage.value = false;
		// console.log('fetched aircraft image');
		// console.log(response.data.data);
		if (response.data.data?.photos) {
			let firstPhoto = response.data.data?.photos[0];

			selectedAircraftImage.value = firstPhoto.thumbnail_large.src;
			selectedAircraftLink.value = firstPhoto.link;
			selectedAircraftPhotographer.value = firstPhoto.photographer;
		}
	}).catch(function (error) {
		loadingAircraftImage.value = false;
		selectedAircraftLink.value = false;
		selectedAircraftPhotographer.value = false;
		// console.log('failed to get image');
	});

}

</script>


<template>

<div class="flex flex-col gap-2">

	<div class="flex flex-wrap gap-2 items-baseline" >
		<div  class="whitespace-nowrap flex-1">
			<div class="infobox-label">{{$t('selected.model')}}</div>
			<div v-if="selectedAircraftDetails">{{selectedAircraftDetails.model}}</div>
		</div>

		<div class="whitespace-nowrap flex-1">
			<div class="infobox-label">{{$t('selected.manufacturer')}}</div>
			<div v-if="selectedAircraftDetails">{{selectedAircraftDetails.manufacturer}}</div>
		</div>
	</div>


	<div v-if="point.rego && !selectedAircraftImage" class="rounded-lg p-2 bg-gray-300 dark:bg-gray-700   dark:text-gray-300 max-w-[350px]  shadow flex flex-col gap-1 items-center">

		<div class="flex gap-2 items-center">
			<PhotoIcon class="h-8 w-8 text-gray-400 " />

			<div v-if="loadingAircraftImage">{{$t('app.loading')}}</div>
			<div v-if="!loadingAircraftImage">{{$t('selected.no-image-found')}}</div>
		</div>
		<div class="text-sm">
			Add image at <a class=" link" href="https://www.planespotters.net/photo/upload" > planespotters.net</a>
		</div>

	</div>

	<div v-if="point.rego && selectedAircraftImage" class="max-w-[350px]">
		<a :href="selectedAircraftLink" class="h-auto bg-gray-500 block  rounded-lg overflow-hidden shadow">
			<img :src="selectedAircraftImage" class="">
		</a>
		<div class="flex items-center justify-between text-xs text-gray-600 dark:text-gray-400 mt-1">
			<div>&copy; {{selectedAircraftPhotographer}}</div>
			<div><a :href="selectedAircraftLink">planespotters.net</a></div>
		</div>
	</div>

</div>

</template>