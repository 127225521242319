<script setup>
import { ref, computed } from 'vue';
import { appStateStore } from "@/stores/AppStateStore.js";
import { settingsStore } from "@/stores/SettingsStore.js";
import { tracksStore } from "@/stores/TracksStore.js";
import { messagesStore } from "@/stores/MessagesStore.js";
import { pointsStore } from "@/stores/PointsStore.js";

const app = appStateStore();
const settings = settingsStore();
const tracks  = tracksStore();
const points  = pointsStore();
const messages = messagesStore();

const sending = ref(false);
const emit = defineEmits(['finished'])

import { pointShortName, makeRandomCode } from '../Helpers.js';

function sendSportsTrackLive() 
{
	if (sending.value==true) {
		messages.warning('Already sending, please wait');
		return;
	}

	sending.value = true;

	let data = createObject();
	let key = app.selected + '-' + makeRandomCode(6);

	console.log(data);

	
	//console.log(data);
	window.axios.put('https://api.sportstracklive.com/v1/external_track/'+key+'/points', data).then(function (response) {
		// console.log(response);
		sending.value = false;


		if (response.data?.url) {
			window.open(response.data.url,'_blank');
			emit('finished');
			return;
		}

		if (response.config?.url) {
			window.open(response.config.url,'_blank');
			emit('finished');
			return;
		}

		emit('finished');

	}).catch(error => {
		messages.error('Error pushing to SportsTrackLive');
		// console.log(error.errors);
		sending.value = false;

		emit('finished');
	});

}

function createObject() {

	let type="visualflight";

	// "paragliding", "hanggliding", "visualflight", "gliding", "helicopter", "hot-air-balloon", "wingsuit", "snowkite", "hike-fly", "speed-riding", "paramotoring", "powerhanggliding"

	switch (points.selected.object_type) {
		case 1: type='gliding'; break;
		case 3: type='helicopter'; break;
		case 6: type='hanggliding'; break;
		case 7: type='paragliding'; break;
		case 17: type='paramotoring'; break;
		case 11: type='hot-air-balloon'; break;
	}

	let dataToSend = {
		user_name: pointShortName(points.selected, 12, false),
		time_zone: app.getMapCenterTimezone(),
		category_name: type,
		point_header: [
			'latitude',
			'longitude',
			'speed',
			'bearing',
			// 'distance',
			'date',
			'altitude'
		],
		points: getPoints()
	}
	return dataToSend;
}

function getPoints() {

	let start = 0;
	let end = 999999999999;

	if (app.selectedRangeStart) start = app.selectedRangeStart;
	if (app.selectedRangeEnd) end = app.selectedRangeEnd;

	let key = app.selected;
	let results = tracks.getTrackForSportsTrackLive(key, start, end);
	return results;
}

// 	{
//   "user_name": "PureTrack User",
//   "time_zone": "Europe/Chisinau",
//   "category_name": "paragliding",
//   "point_header": [
//     "latitude",
//     "longitude",
//     "speed",
//     "bearing",
//     "distance",
//     "date",
//     "altitude"
//   ],
//   "points": [
//     [
//       48.8588443,
//       2.2943506,
//       60,
//       180,
//       120.5,
//       1729517538,
//       35.0
//     ],
//     [
//       48.8588555,
//       2.2943677,
//       62,
//       182,
//       130.2,
//       1729517998,
//       36.0
//     ]
//   ]
// }
</script>

<template>
	<button 
		:title="$t('legend.3d-sportstracklive')" 
		@click="sendSportsTrackLive()"
		class="flex items-center gap-2">
		
		<div>{{ $t('legend.3d-sportstracklive') }}...</div>
		<LoadingHorizontal v-if="sending" />
	</button>
</template>