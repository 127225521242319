<script setup>
import { ref, watch, onMounted, onUnmounted } from 'vue';
import scrollIntoView from 'scroll-into-view-if-needed';
import { appStateStore } from "@/stores/AppStateStore.js";
const app = appStateStore();
import { pointsStore } from "@/stores/PointsStore.js";
const points  = pointsStore();
import { messagesStore } from "@/stores/MessagesStore.js";
const messages = messagesStore();
import { recentAircraftStore } from "@/stores/RecentAircraftStore.js";
const recent  = recentAircraftStore();
import debounce from "lodash/debounce"
import { ChevronDoubleRightIcon, ExclamationTriangleIcon, MagnifyingGlassIcon, ChevronRightIcon, ChevronDownIcon} from "@heroicons/vue/24/outline";

import { pointTypesStore } from "@/stores/PointTypesStore.js";
const pointTypes = pointTypesStore();

import { renderHeight, decodeRow, pointShortName, renderTimeAgoFromUnix } from '../Helpers.js';


import { useMapTools } from "@/Composables/MapTools.js";
const { goTo, goToSelected, hasFollows } = useMapTools();

const emit = defineEmits(['finished'])

const searchText = ref(null);
const searchingForKey = ref(null); // used to identify if actively searching for a map item

const searchResults = ref(null);

const selected = ref(0); // select the first item by default
const searchString = ref('');
const searchAircraft = ref('');


const showSearchResults = ref(true);
const showRecent = ref(true);

function keyPress(e) {
	if (!searchResults.value) return;
	if (!searchResults.value.data) return;

	if (e.keyCode==38) {
		//console.log('Up');
		if (selected.value>0) selected.value--;
		moveIntoView(searchResults.value.data[selected.value].document.id);
	}
	if (e.keyCode==40) {
		//console.log('Down');
		if (selected.value<searchResults.value.data.length-1) selected.value++;
		moveIntoView(searchResults.value.data[selected.value].document.id);
	}
	if (e.keyCode==13) {
		//console.log('Enter');
		//console.log(searchResults.value.data[selected.value]);
		jumpToMapIcon(searchResults.value.data[selected.value].document)
	}
}

function moveIntoView(id) {
	setTimeout(function () {
		const node = document.getElementById(id);
		if (node) scrollIntoView(node, { behavior: 'instant', scrollMode: 'if-needed' });
	}, 1);
}

function onSearchFocus() {
	window.addEventListener('keydown', keyPress);
}
function onSearchBlur() {	
	window.removeEventListener('keydown', keyPress);
}


function search(q) {

	showSearchResults.value = true;
	selected.value=0;

	if (q=='') return false;
	if (q==null) return false;


	window.axios.get('/api/search?q=' + q).then(function (response) {
		searchResults.value = response.data;

		//console.log(searchResults.value);
		if (searchResults.value?.data) {
			for (var i=0; i<searchResults.value.data.length; i++) {

				searchResults.value.data[i].row_number = i;

				//console.log(searchResults.value.data);
				// check if it's a point
				if (searchResults.value.data[i].last_point) {
					searchResults.value.data[i].last_point_decoded = decodeRow(searchResults.value.data[i].last_point);
				}

				
			}
			
		}

		//console.log(searchResults.value);

	}).catch(error => {
		console.log(error.errors);
	});
}



watch(() => searchString.value, (currentValue, oldValue) => {

	if (!currentValue) return;

	if (currentValue.length<2) {
		searchResults.value=[];
		searchText.value = null;
		return;
	}

	searchText.value = currentValue;
	search(searchText.value);
});


watch(() => points.pointsChanged, (currentValue, oldValue) => {
	// console.log('received new points to check for searched item');
	// console.log(searchingForKey.value);

	if (searchingForKey.value) {

		var foundKey = points.getKey(searchingForKey.value);

		// console.log("foundKey: ");
		// console.log(foundKey);

		if (!foundKey) {
			messages.error('No recent location for ' + searchText.value + ' found');
		} else {
			messages.success('Found ' + searchText.value);

			goTo(foundKey.lat, foundKey.long);
			app.showLegend="selected";
		}

		//console.log('finished');
		emit('finished');

		searchingForKey.value = null;
	}
});


function jumpToMapIcon(hit) {
	// console.log('Going to key of ' + hit.id);

	//console.log(hit.location);

	// make sure we are at the latest time
	app.unpause();

	// check if location
	if (hit.location) {
		goTo(hit.location[0], hit.location[1])
	} else {
		// otherwise it's a history item
		app.selected = hit.id;
		searchingForKey.value = hit.id;
		app.restartLoop = true;
		//document.activeElement.blur();
	}

	recent.addToHistory(hit);

}




// 0 closed_airport", 1  "heliport", 2 "large_airport", 3 "medium_airport", 4 "seaplane_base", and 5 "small_airport, 6 "launch_site"

function is_airfield(airfield_type) {
	if (airfield_type==2) return true;
	if (airfield_type==3) return true;
	if (airfield_type==4) return true;
	if (airfield_type==5) return true;
	return false;
}


function checkSearchHeight() {
	// console.log(settings.legendPanelHeight);
	if (settings.legendPanelHeight < 50) {
		setSize(Math.round(window.innerHeight/3));
	}
}

var onSearchInput = debounce(() => {
	searchAircraft.value = search.value;
}, 500)

</script>


<template>

<div class=" flex flex-col  mt-12">

	<div class="flex items-center gap-1 p-2 w-full -mt-12" >

		<input type="text" 
			class="flex-grow rounded-full py-1 px-2 disabled:opacity-40 dark:text-black "
			autocapitalize="none" autocorrect="off" autocomplete="off"  
			size="7"
			@focus="onSearchFocus"
			@blur="onSearchBlur"
			@keyup.enter="onSearchInput"
			@keyup="onSearchInput"
			placeholder="Search" 
			v-model="searchString">

		<MagnifyingGlassIcon class="size-5 cursor-pointer"  @click="onSearchInput" />

	</div>
	<div class="">

		<hr v-if="searchResults" class="ml-2 border-t border-b dark:border-b-gray-700 dark:border-t-black border-b-white border-t-gray-300">

		<div class="flex items-center gap-1  ml-2 cursor-pointer" 
			@click="showSearchResults = !showSearchResults"
			:class="searchResults?.data?.length==0 ? 'cursor-pointer' : 'opacity-50'">
			<ChevronRightIcon v-if="!showSearchResults" class="size-4"></ChevronRightIcon>
			<ChevronDownIcon v-if="showSearchResults" class="size-4"></ChevronDownIcon>
			{{$t('search.results')}}
		</div>


		<div v-if="showSearchResults && !searchResults?.data?.length>0" class="text-sm text-gray-500 px-2 pb-2">
			{{$t('search.no-results')}}
		</div>
		<div v-if="showSearchResults &&  searchResults && searchResults?.data?.length>0">

			<div v-for="hit in searchResults.data" :key="hit.document.id" @keyup.page-down="cl('down-one')">
				
				<button :id="hit.document.id" 
					class="px-2 py-1 hover:bg-gray-300 dark:hover:bg-gray-700 flex items-center w-full"
					:class="[selected==hit.row_number ? 'bg-gray-300 dark:bg-gray-700 dark:hover:bg-gray-700' : '']"
					@click="jumpToMapIcon(hit.document)">

					<div class="flex gap-2 w-full">

						<div class="w-6 h-6 mr-1 rounded-full" v-if="hit.last_point">
							<img class="realtive" :src="pointTypes.getIconImage(hit.document.object_type)" width=32 height=32>
						</div>
						<div  class="w-6 h-6 mr-1 rounded-full" v-if="hit.document.airfield_type">
							<img v-if="is_airfield(hit.document.airfield_type)" 
								class="realtive" 
								src="/images/icons/runway.png" width=32 height=32>
							<img v-if="hit.document.airfield_type==1" 
								class="realtive" 
								src="/images/icons/helipad.png" width=32 height=32>
							<img v-if="hit.document.airfield_type==6" 
								class="realtive" 
								src="/images/icons/launch_site.png" width=32 height=32>
						</div>

						<div class="flex-grow text-left flex items-center gap-2">
							<div>{{hit.document.label}}</div>

							<div class="text-sm text-gray-600 dark:text-gray-400" v-if="hit?.last_point_decoded?.comp">{{hit.last_point_decoded.comp}}</div>
						</div>


						<div v-if="hit.last_point_decoded" class="text-gray-600 dark:text-gray-400">
							{{ renderTimeAgoFromUnix(hit.last_point_decoded.stamp) }}
						</div>

						<div v-if="hit?.document.icao" class="text-gray-600 dark:text-gray-400">
							{{ hit.document.icao }}
						</div>

					</div>

				</button>
			</div>
		</div>

		<hr class="ml-2 my-2 border-t border-b dark:border-b-gray-700 dark:border-t-black border-b-white border-t-gray-300">

		<div class="flex  items-center gap-1 cursor-pointer ml-2" @click="showRecent = !showRecent">

			<ChevronRightIcon v-if="!showRecent" class="size-4"></ChevronRightIcon>
			<ChevronDownIcon v-if="showRecent" class="size-4"></ChevronDownIcon>
			{{$t('search.recent')}}
		</div>

		<div v-if="showRecent && recent.recent.length>0">
			<div v-for="doc in recent.recent" :key="doc.id">
				<button class="px-2 py-1 hover:bg-gray-300 dark:hover:bg-gray-700 flex items-center w-full"
					@click="jumpToMapIcon(doc)">

						<div class="w-6 h-6 mr-1 rounded-full" v-if="doc.object_type">
							<img class="realtive" :src="pointTypes.getIconImage(doc.object_type)" width=32 height=32>
						</div>
						<div  class="w-6 h-6 mr-1 rounded-full" v-if="doc.airfield_type">
							<img v-if="is_airfield(doc.airfield_type)" 
								class="realtive" 
								src="/images/icons/runway.png" width=32 height=32>
							<img v-if="doc.airfield_type==1" 
								class="realtive" 
								src="/images/icons/helipad.png" width=32 height=32>
							<img v-if="doc.airfield_type==6" 
								class="realtive" 
								src="/images/icons/launch_site.png" width=32 height=32>
						</div>

						{{doc.label}}
				</button>
			</div>
		</div>

		<div class="w-full">
			<button v-if="recent.recent.length>0" 
				class="text-sm px-2 hover:bg-gray-300 dark:hover:bg-gray-700 py-1 w-full text-left "
				@click="recent.clearRecent()">
				{{$t('search.clear-history')}}
			</button>
			<div v-if="recent.recent.length==0" class="text-sm text-gray-500 px-2 pb-2">
				{{$t('search.no-recent-searches')}}
			</div>
		</div>
	</div>
</div>


</template>