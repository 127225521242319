<script setup>
import { ref,  defineModel } from 'vue';

// null=disabled, 0=off, 1==on, 2=half

const model = defineModel()

let props = defineProps({
	id: String,
	disabled: Boolean
});

function update() {
	if (disabled) return;
	model.value = !model.value;
}

if (model.value==null) model.value=false;
</script>

<template>
	
	<button :id="props.id" :class="[disabled ? 'opacity-30 cursor-default' : 'cursor-pointer']">
		<div v-show="model===false"  class="rounded-full shadow-inner shadow-gray-700 dark:shadow-black p-2 flex gap-1 items-center text-xs uppercase bg-white dark:bg-gray-700 text-gray-500 "
			:class="[disabled ? '' : 'hover:bg-gray-200 dark:hover:bg-gray-800']"
			>
			<div class="rounded-lg bg-gray-400 dark:bg-gray-500 w-4 h-4"></div>
			Off
		</div>
		<div v-show="model===true" class="rounded-full shadow-inner shadow-gray-700 dark:shadow-black p-2 flex gap-2 items-center text-xs uppercase bg-white dark:bg-gray-700 text-gray-500"
		:class="[disabled ? '' : 'hover:bg-gray-200 dark:hover:bg-gray-800']">
			On
			<div class="rounded-lg bg-lime-600 dark:bg-lime-500 w-4 h-4"></div>
		</div>
	</button>

</template>
