<script setup>
import { ref, onMounted, onBeforeUnmount, watch, computed } from 'vue';

import { settingsStore } from "@/stores/SettingsStore.js";
import { appStateStore } from "@/stores/AppStateStore.js";
import { messagesStore } from "@/stores/MessagesStore.js";
const app = appStateStore();
const settings = settingsStore();
const messages = messagesStore();

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const open = ref(false);
const loading = ref(0);
const trips = ref([]);
const days = ref([]);
const loadingDays = ref(0);
const interval = ref(null);
const currentDate = ref(null);

const show = ref('flights');

const props = defineProps(['timezone']);

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

import { XMarkIcon, Bars3Icon, ChevronRightIcon, ChevronDownIcon, BookOpenIcon, CalendarIcon, LockClosedIcon } from "@heroicons/vue/24/outline";


import { formatTimeFromJSDate, renderHoursAndMinutes, apiDateToJS, apiDateToDayJS, formatDateTime } from '../Helpers.js';


watch(() => app.selected, (currentValue, oldValue) => {
	show.value='flights';
	loadTrips();
});

// reload dates if they change
watch(() => currentDate.value, (currentValue, oldValue) => {
	show.value='flights';
	loadTrips();
});


function loadTripDays() {
	if (!open.value) return;

	loadingDays.value++;
	window.axios.get('/api/trips/' + app.selected + '/dates').then(function (response) {
		days.value = response.data.data;
		for (var i=0; i<days.value.length; i++) {
			if (!app.subscribed && i>=2) {
				days.value[i].hide = true;
			}
		}
		loadingDays.value--;
	}).catch(error => {
		console.log(error);
		messages.error("Trip Days not loaded");
		loadingDays.value--;
	});
}

function loadTrips() {
	if (!open.value) return;
	//console.log('loading trips');
	loading.value++;

	let url = '/api/trips/' + app.selected + '?&limit=10&timezone=' + props.timezone;

	if (currentDate.value!==null) {
		let day = dayjs(currentDate.value);
		url = url + '&date=' + day.format('YYYY-MM-DD');
	}

	window.axios.get( url ).then(function (response) {
		trips.value = response.data.data;

		for (var i=0; i<trips.value.length; i++) {
			if (!app.subscribed && i>=2) {
				trips.value[i].hide = true;
			}
		}

		loading.value--;
	}).catch(error => {
		console.log(error);
		messages.error("Trips not loaded");
		loading.value--;
	});
}

onMounted(() => {
	loadTrips();
	loadTripDays();
	interval.value = setInterval(loadTrips, 30000);
});
onBeforeUnmount(() => {
	//console.log('clearing intgerval');
	clearInterval(interval.value);
});

function isSelectedTrip(trip) {
	if (!app.selectedTrackStart) return false;
	return app.selectedTrackStart.unix()==apiDateToDayJS(trip.start_ts).unix();
}


function toggleShow() {
	if (show.value=='choose') show.value='flights';
	else show.value='choose';
}

function clickTrip(trip) {
	if (trip.hide) {
		messages.warning(t('app.upgrade-to-pro-required'));
		return;
	}
	app.zoomToTrack = true;
	app.loadTimeRange(trip.start_ts, trip.end_ts, 2);
}
function clickDay(day) {
	if (day.hide) {
		messages.warning(t('app.upgrade-to-pro-required'));
		return;
	}
	trips.value=[];
	currentDate.value = day.day; 
	show.value='flights';
}



</script>


<template>
<div>


<hr class="ml-2 border-t border-b dark:border-b-gray-700 dark:border-t-black border-b-white border-t-gray-300">

<div class="flex flex-col ml-2 gap-2 mt-2">

	<button 
		v-on:click="open = !open; loadTrips(); loadTripDays();"
		class="text-left flex gap-1 items-center flex-wrap">
		<ChevronRightIcon v-if="!open" class="size-4 flex-grow-0"></ChevronRightIcon>
		<ChevronDownIcon v-if="open" class="size-4 flex-grow-0"></ChevronDownIcon>

		<BookOpenIcon class="size-4" />
		<div class='text-sm mr-2'> {{ $t('trips.flights') }} </div>

		<LoadingHorizontal  v-if="loading" />

		<div class="flex-grow"></div>

		<div v-show="open" class="text-gray-600 dark:text-gray-400 text-sm   hover:bg-gray-300 dark:hover:bg-gray-700 cursor-pointer flex items-center gap-2 justify-end p-1 rounded-md"
		@click.stop="toggleShow()">
			<CalendarIcon class="size-4"></CalendarIcon>
			<div v-if="currentDate==null">{{ $t('trips.latest-10') }}</div>
			<div v-if="currentDate!=null">{{formatDateTime(currentDate, false, false)}}</div>
		</div>
	</button>

	<div class="flex flex-col flex-wrap gap-2 " v-if="open">
	
		<div v-if="trips.length==0 && show=='flights' && !loading" class="text-gray-600 dark:text-gray-400 text-sm">
			{{ $t('trips.no-flights-yet') }}
		</div>

		<table v-if="trips.length>0 && show=='flights'" class="w-full">
			<tr>
				<th class="text-left text-sm text-gray-600 dark:text-gray-400 font-normal">
					{{ $t('trips.start') }}
				</th>
				<th class="text-left text-sm text-gray-600 dark:text-gray-400 font-normal">
					{{ $t('trips.end') }}
				</th>
				<th class="text-right text-sm text-gray-600 dark:text-gray-400 font-normal">
					{{ $t('trips.duration') }}
				</th>
			</tr>
			<tr v-for="trip in trips" 
			:key="trip.start_ts"
			@click="clickTrip(trip)"
			class="hover:bg-gray-300 dark:hover:bg-gray-700 cursor-pointer"
			:class="[
				isSelectedTrip(trip) ? 'dark:bg-gray-600 bg-gray-400' : '',
				trip.hide? 'opacity-50': ''
			]">
				<td class="text-left text-sm rounded-l-md pl-1 align-top">
					{{ formatDateTime(apiDateToJS(trip.start_ts), true, false, props.timezone, settings.timeUnits, settings.timezoneUnits) }}
					<div class="text-gray-600 dark:text-gray-400" v-if="trip.start_location">{{trip.start_location.icao}} {{trip.start_location.name}}</div>
				</td>
				<td class="text-left text-sm  align-top" v-if="!trip.hide">

					<div v-if="trip.end_ts">{{ formatTimeFromJSDate(apiDateToJS(trip.end_ts), false, false, props.timezone, settings.timeUnits, settings.timezoneUnits) }}</div>

					<div v-if="!trip.end_ts">In Progress</div>

					<div class="text-gray-600 dark:text-gray-400" v-if="trip.end_location">{{trip.end_location.icao}} {{trip.end_location.name}}</div>


				</td>
				<td class="text-right text-sm rounded-r-md pr-1  align-top" v-if="!trip.hide">
					<div v-if="trip.duration">{{ renderHoursAndMinutes(trip.duration) }}</div>
					<div v-if="!trip.duration">
						{{ renderHoursAndMinutes(
							dayjs.utc().diff(apiDateToDayJS(trip.start_ts), 'second')
						) }}
					</div>
				</td>
				<td class="text-center text-sm rounded-r-md pr-1  align-top" colspan="2" v-if="trip.hide">
					<a href="/upgrade" class="flex items-center gap-1 justify-center"><LockClosedIcon class="h-4 w-4" /> Upgrade</a>
				</td>
			</tr>
		</table>



		<table class="w-full" v-if="show=='choose'">
			<tr class="hover:bg-gray-300 dark:hover:bg-gray-700 cursor-pointer">
				<td class="rounded-md px-1" colspan="2" 
				@click=" trips=[]; currentDate=null; show='flights';">
					{{ $t('trips.show-latest') }}
				</td>
			</tr>
			<tr v-for="day in days" :key="day.day"
			class="hover:bg-gray-300 dark:hover:bg-gray-700 cursor-pointer"
			@click="clickDay(day)"
			:class="[day.hide? 'opacity-50': '']">
				<td class="pr-4 rounded-l-md pl-1">{{ formatDateTime(day.day, false, true) }}</td>
				<td class="pr-4 rounded-r-md">
					{{ day.count }} flight<span v-if="day.count!==1">s</span>
				</td>
			</tr>
		</table>


		<div v-if="open && show=='choose'" class="">
			<VueDatePicker auto-apply  :teleport="true"
			:enable-time-picker="false" 
			v-model="currentDate"
			class=""></VueDatePicker>
		</div>


	</div>


</div>



</div>
</template>