<script setup>
import { ref, computed } from 'vue';
import boundingBoxes from '/data/bounding-boxes.json';
import { appStateStore } from "@/stores/AppStateStore.js";
import { settingsStore } from "@/stores/SettingsStore.js";
import { mapStyles } from "@/stores/ProMapStyles.js";

import { useI18n } from 'vue-i18n'
const { t } = useI18n()

import ToggleSwitch from '@/PureComponents/ToggleSwitch.vue';

import { CogIcon, XMarkIcon, ArrowRightIcon, ArrowLeftIcon, TrophyIcon, UserGroupIcon, PlusCircleIcon, LockClosedIcon } from "@heroicons/vue/24/outline";

const app = appStateStore();
const settings = settingsStore();


function clickGliding() {
	app.setMode('gliding');
	app.show = null;
}
function clickMarine() {
	app.setMode('marine');
	app.show = null;
}
function clickGA() {
	app.setMode('ga');
	app.show = null;
}
function clickFreeflight() {
	app.setMode('freeflight');
	app.show = null;
}
function clickAll() {
	app.setMode('all');
	app.show = null;
}

var recentMenu = [
	{id: 30, label: "30mins (Live)", alt: "30mins (Live)", pro: false},
	{id: 360, label: "6hrs (Recent)", alt: "6hrs (Recent)", pro: false},
	{id: 1440, label: "24hrs (Everyone Today)", alt: "24hrs (Everyone Today)", pro: true},
];


</script>

<template>

	<div class="flex w-full sm:max-w-[600px] mx-auto overflow-hidden">
		<div class=" flex p-2 sm:px-8 sm:py-4 flex-col w-full overflow-hidden">
			<div class="frosted-panel flex flex-col overflow-hidden">

				<div class="flex items-center px-4 pt-4 pb-2 gap-4">

					<button title="close" class="round-button" @click="app.show=app.showBack">
						<ArrowLeftIcon class="w-6 h-6" />
					</button>

					<h2 class="menu-title flex-grow ">{{ $t('modes-menu.change-mode') }}</h2>

					<button title="close" class="round-button" @click="app.show=null">
						<XMarkIcon class="w-6 h-6" />
					</button>

				</div>


				<div class="flex flex-col overflow-y-scroll px-4 pb-4 pt-6 gap-4">


					<div class="menu-section">

						<div class="menu-row ">

							<div class="flex flex-col gap-2">

								<h3>{{ $t('layers-menu.hide-markers-older-than') }}</h3>

								<div class="flex flex-wrap gap-2">
									<button v-for="item in recentMenu"
										:title="item.alt"
										:key="item.id"
										:disabled="!app.subscribed && item.pro"
										class="border border-lime-600 dark:border-lime-500 py-1 px-2 rounded-lg cursor-pointer disabled:opacity-50 flex items-center gap-1"
										@click="settings.filterMaxAge = item.id; app.show=null;"
										:class="settings.filterMaxAge==item.id ? 'bg-lime-600 dark:bg-lime-500 text-white dark:text-black' : 'hover:bg-gray-200 dark:hover:bg-gray-900'">
											{{ item.label }}
											<LockClosedIcon class="h-4 w-4" v-show="!app.subscribed && item.pro" />
									</button>
								</div>
							</div>


						</div>
					</div>

					<div class="menu-section">

						<div class="menu-row menu-row-clickable" @click="clickAll()">
							<img src="/images/icons/jet_w.svg" class="w-8 h-8">

							<div class="flex flex-col flex-grow">
								<h2 class="flex-grow text-lg">{{ $t('welcome.show-all') }}</h2>
								<div class="text-gray-600 dark:text-gray-300 text-sm">{{ $t('welcome.all-description') }}</div>
							</div>

							<button :title="$t('welcome.show-all')" class="round-button">
								<ArrowRightIcon class="w-3 h-3" />
							</button>
						</div>


						<div class="menu-row menu-row-clickable" @click="clickGA()">
							<img src="/images/icons/plane_w.svg" class="w-8 h-8">

							<div class="flex flex-col flex-grow">
								<h2 class="text-lg">{{ $t('welcome.general-aviation') }}</h2>
								<div class="text-gray-600 dark:text-gray-300 text-sm">{{ $t('welcome.ga-description') }}</div>
							</div>

							<button :title="$t('welcome.general-aviation')" class="round-button">
								<ArrowRightIcon class="w-3 h-3" />
							</button>
						</div>

						<div class="menu-row menu-row-clickable" @click="clickGliding()">
							<img src="/images/icons/glider_w.svg" class="w-8 h-8">

							<div class="flex flex-col flex-grow">
								<h2 class="text-lg">{{ $t('welcome.gliding') }}</h2>
								<div class="text-gray-600 dark:text-gray-300 text-sm">{{ $t('welcome.gliding-description') }}</div>
							</div>

							<button :title="$t('welcome.gliding')" class="round-button">
								<ArrowRightIcon class="w-3 h-3" />
							</button>
						</div>

						<div class="menu-row menu-row-clickable" @click="clickFreeflight()">
							<img src="/images/icons/paraglider_w.svg" class="w-8 h-8">

							<div class="flex flex-col flex-grow">
								<h2 class="flex-grow text-lg">{{ $t('welcome.freeflight') }}</h2>
								<div class="text-gray-600 dark:text-gray-300 text-sm">{{ $t('welcome.freeflight-description') }}</div>
							</div>

							<button :title="$t('welcome.freeflight')" class="round-button">
								<ArrowRightIcon class="w-3 h-3" />
							</button>
						</div>

						<div class="menu-row menu-row-clickable" @click="clickMarine()">
							<img src="/images/icons/sailboat_w.svg" class="w-8 h-8">

							<div class="flex flex-col flex-grow">
								<h2 class="flex-grow text-lg">{{ $t('welcome.marine') }}</h2>
								<div class="text-gray-600 dark:text-gray-300 text-sm">{{ $t('welcome.marine-description') }}</div>
							</div>

							<button :title="$t('welcome.marine')" class="round-button">
								<ArrowRightIcon class="w-3 h-3" />
							</button>
						</div>

					</div>

				</div>


			</div>


		</div>
	</div>

</template>