<script setup>
import { ref, computed, onMounted} from 'vue';
import { appStateStore } from "@/stores/AppStateStore.js";
import { settingsStore } from "@/stores/SettingsStore.js";
import { messagesStore } from "@/stores/MessagesStore.js";
import {Html5QrcodeScanner} from "html5-qrcode";

const emit = defineEmits(['found']);

let html5QrcodeScanner = null;


function onScanSuccess(decodedText, decodedResult) {
	// handle the scanned code as you like, for example:
	//console.log(`Code matched = ${decodedText}`, decodedResult);
	html5QrcodeScanner.clear();
	emit('found', decodedResult);
}

function onScanFailure(error) {
	// handle scan failure, usually better to ignore and keep scanning.
	// for example:
	console.warn(`Code scan error = ${error}`);
}

onMounted(() => {
	html5QrcodeScanner = new Html5QrcodeScanner(
		"reader",
		{ fps: 10, qrbox: {width: 250, height: 250} },
		/* verbose= */ false);
	html5QrcodeScanner.render(onScanSuccess, onScanFailure);

});

</script>

<style>
.html5-qrcode-element {
	@apply border border-gray-600 py-1 px-4 rounded bg-white cursor-pointer text-gray-800 block mb-2 disabled:opacity-50;
	text-decoration: none;

}


#reader__dashboard_section {
	@apply flex flex-col items-center max-h-[300px];
}

#reader__scan_region img {
	@apply inline max-h-[200px];
}
</style>

<template>
	<div class="pb-4 border-2">
		<div id="reader"></div>
	</div>
</template>