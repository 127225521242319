<script setup>
import { ref, computed } from 'vue';
import boundingBoxes from '/data/bounding-boxes.json';
import { appStateStore } from "@/stores/AppStateStore.js";
import { settingsStore } from "@/stores/SettingsStore.js";
import { mapStyles } from "@/stores/ProMapStyles.js";

import ToggleSwitch from '@/PureComponents/ToggleSwitch.vue';

import { messagesStore } from "@/stores/MessagesStore.js";
const messages = messagesStore();

import { CogIcon, XMarkIcon, ArrowRightIcon, ArrowLeftIcon, LockClosedIcon, CheckIcon, ChevronDownIcon, ChevronRightIcon, DocumentDuplicateIcon, TrophyIcon } from "@heroicons/vue/24/outline";

const app = appStateStore();
const settings = settingsStore();

import { copyToClipboard } from '../Helpers.js';

function toggleClass() {
	addLatestTasks(); // for the meantime, just load them all again
}

import { useContestManager } from "@/Composables/UseContestManager.js";
const { currentContest, tasksData, clearContest, classesList, loadingContest, loadingClasses, addLatestTasks, pilots, filterNonContestPilots, classPilots } = useContestManager();

function calcUrl() {
	return window.location.protocol + "//" + window.location.host + '/?c=' + currentContest.value.id;
}


function copyUrl() {
	copyToClipboard(calcUrl()); 
	messages.success('Link to contest copied to clipboard');
}

</script>

<template>

	<div class="flex w-full sm:max-w-[600px] mx-auto overflow-hidden">
		<div class=" flex p-2 sm:px-8 sm:py-4 flex-col w-full overflow-hidden">
			<div class="frosted-panel flex flex-col overflow-hidden">

				<div class="flex items-center px-4 pt-4 pb-2 gap-4">

					<button title="close" class="round-button" @click="app.show=null">
						<ArrowLeftIcon class="w-6 h-6" />
					</button>

					<h2 v-if="currentContest" class="menu-title flex-grow">
						<TrophyIcon class="w-6 h-6 opacity-60 inline mr-1" /> {{ currentContest.name }}
					</h2>

					<button title="close" class="round-button" @click="app.show=null">
						<XMarkIcon class="w-6 h-6" />
					</button>

				</div>



				<div class="flex flex-col overflow-y-scroll px-4 pb-4 pt-6 gap-4" v-if="currentContest">


					<h3 class="menu-section-title">Classes</h3>


					<div v-if="loadingContest>0" class="flex my-4">
						<spinner class="w-5 h-5" /> Loading Contest...
					</div>


					<div  class="menu-section">

						<div class="menu-row" v-if="loadingContest>0">
							<div class="flex items-center">
								<spinner />  {{ $t('app.loading') }}
							</div>
						</div>


						<div v-if="loadingContest==0 && classesList.length==0" class="menu-row text-gray-600 dark:text-gray-400">
							{{ $t('contests-menu.no-classes') }}
						</div>

						<label :for="'cl_' + clss.id"
							:key="clss.id"
							v-for="clss in classesList" 
							class="menu-row menu-row-clickable flex items-center cursor-pointer py-1 px-2 justify-between">


							<div class="flex items-center gap-2">

								<spinner class="w-4 h-4" v-if="clss.loadingTask"  />

								<input v-show="!clss.loadingTask" type="checkbox" :id="'cl_' + clss.id" :value="true" v-model="clss.show" @change="toggleClass()">

								{{clss.name}}
							</div>

							<div class="rounded-full bg-gray-700 text-white dark:bg-gray-300 dark:text-black  py-1 px-2">
								{{classPilots(clss.name).length}} 
								pilot<span v-show="classPilots(clss.name).length!==1">s</span>

							</div>
						</label>


					</div>


					<div class="menu-section">

						<label for="filterComp" class="menu-row menu-row-clickable ">
							<div>
								<input class="mr-1" type="checkbox" id="filterComp" :value="true" v-model="filterNonContestPilots" @change="app.restartLoop=true;"> {{$t('contests-menu.filter-to-contest')}}
							</div>
						</label>


						<div class="menu-row  justify-between w-full">

							<div class="flex flex-col gap-1 items-start whitespace-nowrap max-w-full overflow-hidden ">
								<span>{{ $t('contests-menu.puretrack-link') }}</span>
								<div class="text-sm max-w-full text-gray-600 dark:text-gray-400 overflow-hidden  overflow-ellipsis">{{calcUrl()}}</div>
							</div>

							<button :title="$t('app.copy-to-clipboard')" class="round-button"  @click="copyUrl()">
								<DocumentDuplicateIcon class="w-6 h-6" />
							</button>

						</div>

					</div>

					<a class="menu-button menu-row-clickable" :href="'https://soaringspot.com' + currentContest.source_url" >
						{{$t('contests-menu.view-results-soaringspot')}}
					</a> 

					<button class="menu-button menu-row-clickable" @click="clearContest(); app.show=null;"> {{$t('contests-menu.close-contest')}}</button> 

				</div>



			</div>


		</div>
	</div>

</template>