<script setup>
import { ref, watch, onBeforeUnmount } from 'vue';
import { settingsStore } from "@/stores/SettingsStore.js";
import { appStateStore } from "@/stores/AppStateStore.js";
import { zonesStore } from "@/stores/ZonesStore.js";
import { messagesStore } from "@/stores/MessagesStore.js";

import { useDrawZones } from "@/Composables/UseDrawZones.js";
import { useMapTools } from "@/Composables/MapTools.js";

const settings = settingsStore();
const app = appStateStore();
const zones = zonesStore();
const messages = messagesStore();
const drawZones = useDrawZones();
const mapTools = useMapTools();

import { XMarkIcon, ChevronDownIcon, LockClosedIcon, TrashIcon, ChevronDoubleUpIcon, ChevronDoubleDownIcon, PlusCircleIcon } from "@heroicons/vue/24/outline";
import { distanceToMeters, renderDistance, renderDistanceUnits } from '../Helpers.js';

const emit = defineEmits(['close']);
const mode = ref('list'); // or add mode



watch(() => zones.added, (currentValue, oldValue) => {
	if (currentValue==true) {
		zones.added = false;
		switchToListMode();
	}
});

function addZone() {
	if (zones.newZone.lat==null) {
		messages.error('Click on the map first to position the zone');
		return;
	}

	zones.add();
}

function cancel() {
	zones.clearNew();
	emit('close');
}

function switchToAddMode() {
	mode.value='add';
	drawZones.constructNewZoneLayers(app.map);
	app.map.on('click', onMapClick); // enable the map clicking
}
function switchToListMode() {
	mode.value='list';
	drawZones.destructNewZoneLayers();
	app.map.off('click', onMapClick); // disable the map clicking
}

function onMapClick(e) {
	var coordinates = e.lngLat;
	zones.newZone.lat = coordinates.lat;
	zones.newZone.long = coordinates.lng;
}


function setupMap() {
	//drawZones.constructZoneLayers(app.map);
	zones.load();
}

function deleteZone(zone) {
	zones.deleteZone(zone);
}

function gotoZone(zone) {
	drawZones.goToZone(zone);
}

setupMap();

onBeforeUnmount(() => {
	//drawZones.destructZoneLayers();
	drawZones.destructNewZoneLayers();
	// remove zones

	if (!settings.showZones) {
		zones.clear();
	}
	app.map.off('click', onMapClick);
});

</script>

<template>

<div class="flex flex-col pointer-events-auto overflow-y-scroll p-2  rounded pb-8">


	<div v-if="mode=='add'">

		<h2 class="">Add Zone</h2>

		<p class="caption">Click on map to position zone</p>

		<label class="caption mt-2 block">Name</label>

		<input type="text" class="input" v-model="zones.newZone.name">

		<label class="caption mt-4 block">Radius</label>

		<div class="flex gap-2 items-center">
			<input type="text" class="input w-1/2" size="6" v-model="zones.newZone.radiusRaw">
			{{renderDistanceUnits(settings.distanceUnits)}}
		</div>

		<div class="flex gap-2 mt-4">
			<button class="infobox infobox-clickable" @click="addZone();">Add Zone</button>
			<button class="infobox infobox-clickable" @click="switchToListMode()">Cancel</button>
		</div>

	</div>


	<div v-if="mode=='list'">

		<div class="flex w-full my-2 justify-between">

			<h2>My Zones</h2>

			<button class="infobox infobox-clickable flex gap-1 items-center" @click="switchToAddMode()">
				<PlusCircleIcon class="size-4" />
				Add Zone
			</button>

			<button class="infobox infobox-clickable flex gap-1 items-center" @click="settings.sideBarPage='list'">
				<XMarkIcon class="size-4" />
				Done
			</button>
		</div>
			

		<div >
			<div class="flex justify-between gap-4 mb-2" v-for="zone in zones.zones">
				
				<div class="flex-grow">{{zone.name}}</div>
				<div>{{renderDistance(zone.radius, settings.distanceUnits)}}</div>
				<button class="btn btn-dark btn-tiny" @click="gotoZone(zone)">Go</button>
				<TrashIcon v-on:click="deleteZone(zone)" class="h-5 w-5  cursor-pointer pointer-events-auto" />
			</div>

			<div v-if="zones.zones?.length==0" class="text-gray-400 text-sm">None</div>
		</div>

	</div>

</div>
</template>